






























import { Component, Vue } from "vue-property-decorator";
import { Subscription } from '../utils/rx';
import { login, loginIn$ } from './authentication';
import { dataSet } from '../dataSet';

@Component
export default class LoginPage extends Vue {
    @Subscription(loginIn$)
    loading!: boolean;

    model = {
        email: '',
        password: ''
    }
    rules = {
        email: [{ required: true, message: 'E-mail requis' }, { type: 'email', message: "Format de l'e-mail invalide" }],
        password: [{ required: true, message: 'Mot de passe requis' }]
    }
    data = dataSet;

    resetPassword() {
      this.$router.replace('/anonymous/reset-password');
    }

    submit() {
      const form = this.$refs['form'] as Vue & { validate: (cb: (validate: boolean) => Promise<void>) => void };
        form.validate(async (valid: boolean) => {
            if (valid) {
                try {
                    await login(this.model);
                    const redirect = this.$router.currentRoute.query['returnUrl'] || '/';
                    this.$router.replace(redirect as string);
                } catch (ctx) {
                    this.$message({
                        showClose: true,
                        message: ctx.error.errorId === 'GloballyDisabledUser' ? 'Votre compte a été désactivé' : 'E-mail ou mot de passe invalide',
                        type: 'error'
                    });
                }
            }
        });
    }
}
