// Vue dependencies
import Vue from 'vue'

// Vue router
import router from './main.router'

// App
import App from './App.vue'

// Auth
import { refreshAuthentication, refreshUserProfile, authenticateRequests, authenticatedUser$, authenticationLevel$ } from './modules/authentication';
import locale from 'element-ui/lib/locale/lang/fr'

// CRS
import { endpoint, loadCrs } from './modules/crs/crs';

import { loadDataSet } from './modules/dataSet';

import ElementUI from 'element-ui';
import '@/theme/element/index.css';

import * as VueRx from 'vue-rx'
import Rx from 'rxjs/Rx.js'

// Axios progress bar
import { loadProgressBar } from 'axios-progress-bar'

// Echarts
import Echarts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/scatter';
// import 'echarts/lib/visual'

import Component from 'vue-class-component'
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

Promise.all([loadDataSet()]);

Vue.component('chart', Echarts);

authenticatedUser$.subscribe(async user => {
  if (user) {
    authenticateRequests();
    refreshUserProfile();
    try {
      if (await endpoint.getMetadata()) {
        endpoint.reloadMetadata();
      }
    } catch (e) {
      if (e instanceof Error) {
        console.debug(e.message);
      } else {
        console.debug(e);
      }
    }
  }
});

// Redirects when authentication expires
authenticationLevel$.skip(1).subscribe( level => {
  if (level <= 1 && router.currentRoute.path !== '/anonymous' && router.currentRoute.path !== '/anonymous/reset-password') {
    router.replace(`/anonymous?returnUrl=${router.currentRoute.path}`);
  }
})
Vue.use(ElementUI, { locale });
Vue.use(VueRx, Rx)


loadProgressBar({});
// Bootstrap root after auth refresh
Promise.all([refreshAuthentication(), loadCrs()]).then(() => {
    endpoint.initialize();
    new Vue({
    el: '#app',
    router,
    render: h => h(App)
  })
});
