export interface IPaginatedQuery {
    perPage: number;
    direction: SearchDirection;
}

export enum SearchDirection {
    Reset = 0,
    Prev = 1,
    Next = 2,
    Current = 3
}

export interface IQueryResult<TResult> {
    items: Array<TResult>;
}

export type ProtectedMemory = string;

export interface IPaginatedQueryResult<TResult> extends IQueryResult<TResult> {
    canGoPrev: boolean;
    canGoNext: boolean;
    count: number;
    memory: ProtectedMemory;
}