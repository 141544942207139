
































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IDashboardModel, ISensorsModel } from "../dashboard.model";
import {
  dashboardSensorUpdateConfig,
  DashboardSensorUpdateConfig,
} from "@/commands/dashboard/DashboardSensorUpdateConfig";
import moment, { duration } from "moment";
import { BinIdResult } from "@/queries/dashboard/BinIdQuery";
import { getSensorBins } from "@/backend/binId";
import { CommandResultStatus } from "@/modules/crs/crs";

@Component
export default class SensorSettings extends Vue {
  @Prop({ required: true })
  sensorModel!: ISensorsModel;
  @Prop({ required: true })
  dashboardId!: number;
  currentSliperyHour: Date = ("" as any) as Date;
  productionUnit: "kg" | "volume" = "volume";
  minVolume = -1;
  maxVolume = -1;
  density = 0;
  histCumulTreshold = 0;
  binId = 0;
  bins: BinIdResult[] = [];
  mounted() {
    this.resetValues();
  }

  @Watch("sensorModel", { deep: true })
  resetValues() {
    getSensorBins(this.sensorModel.sensorId).then((s) => (this.bins = s));
    const cfg = this.sensorModel.displayConfig;

    /*
    ** Because the duration is saved as '1970-01-01Thh:mm:ss' in UTC
    ** We need to display it as UTC but ElementUI only supports locale timezone
    ** So we have to set the time manually to get rid of the UTC offset
    */
    this.realtime = new Date(0);
    this.realtime.setHours(cfg.defaultRealtimeDuration.getUTCHours());
    this.realtime.setMinutes(cfg.defaultRealtimeDuration.getUTCMinutes());
    this.realtime.setSeconds(cfg.defaultRealtimeDuration.getUTCSeconds());

    this.currentSliperyHour = new Date(0);
    this.currentSliperyHour.setHours(cfg.defaultSliperyHour.getUTCHours());
    this.currentSliperyHour.setMinutes(cfg.defaultSliperyHour.getUTCMinutes());
    this.currentSliperyHour.setSeconds(cfg.defaultSliperyHour.getUTCSeconds());
    // this.currentSliperyHour = cfg.defaultSliperyHour.toLocaleTimeString('fr-fr');

    this.framedDates = cfg.defaultFramedTime;
    this.thresholdHistCumul = cfg.thresholdHistCumul;
    this.density = cfg.density;
    this.productionUnit = cfg.useWeight ? "kg" : "volume";
    this.minVolume = cfg.minVolume;
    this.maxVolume = cfg.maxVolume;
    this.binId = cfg.defaultBinId;
  }

  framedDates: Date[] = [];
  realtime: Date = ("" as any) as Date;
  thresholdHistCumul: number | "" = "";
  async updateConfig() {
    /*
    ** We god rid of the UTC previously (in resetValues())
    ** So now we need to reverse the process to save it correctly
    */
    const realtimeDuration = new Date(0);
    realtimeDuration.setUTCHours(this.realtime.getHours());
    realtimeDuration.setUTCMinutes(this.realtime.getMinutes());
    realtimeDuration.setUTCSeconds(this.realtime.getSeconds());

    const sliperyDuration = new Date(0);
    sliperyDuration.setUTCHours(this.currentSliperyHour.getHours());
    sliperyDuration.setUTCMinutes(this.currentSliperyHour.getMinutes());
    sliperyDuration.setUTCSeconds(this.currentSliperyHour.getSeconds());

    this.sensorModel.displayConfig = {
      defaultRealtimeDuration: realtimeDuration,
      currentRealtimeDuration: realtimeDuration,
      defaultFramedTime: this.framedDates,
      currentFramedTime: this.framedDates,
      thresholdHistCumul: this.thresholdHistCumul as number,
      currentSliperyHour: sliperyDuration,
      defaultSliperyHour: sliperyDuration,
      useWeight: this.productionUnit == "kg",
      density: this.density,
      minVolume: this.minVolume,
      maxVolume: this.maxVolume,
      defaultBinId: this.binId,
      currentBinId: this.binId,
    };

    const result = await dashboardSensorUpdateConfig(
      new DashboardSensorUpdateConfig(
        this.dashboardId,
        this.sensorModel.sensorId,
        JSON.stringify(this.sensorModel.displayConfig)
      )
    );

    if (result.status === CommandResultStatus.Ok)
      this.$message({ message: 'Paramètres enregistrés avec succès', type: 'success', center: true });
    else
      this.$message({ message: 'Erreur, paramètres non-enregistrés', type: 'error', center: true });
  }

  public cancel(): void {
    this.resetValues();
    this.$message({ message: 'Paramètres restaurés', type: 'info', center: true });
  }
}
