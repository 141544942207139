import { createDecorator } from 'vue-class-component';
import { Observable } from 'rxjs/Observable';

export function Subscription(observableSource: Observable<any>) {
    return createDecorator(function (options: any, propertyName: string) {
        const { subscriptions = () => { } } = options;

        options.subscriptions = function () {
            return {
                [propertyName]: observableSource,
                ...subscriptions.bind(this)()
            }
        };
    });
}

export interface IReadOnlyBehaviorSubject<T> extends Observable<T> {
    value: T;
    getValue(): T;
} 