import http from "axios";

export interface DataSet {
  documentTitle: string;
  emailPlaceholder: string;
  primaryColor: string;
  secondaryColor: string;
}

export let dataSet: DataSet = {
  documentTitle: "",
  emailPlaceholder: "",
  primaryColor: "",
  secondaryColor: ""
}

export async function loadDataSet() {
  const content = await http.get('/content.json');
  if (content.data.dataSet === "tamisoft") {
    dataSet = {
      documentTitle: "FragMo",
      emailPlaceholder: "email@automelec.com",
      primaryColor: "#E79300",
      secondaryColor: "#E79300"
    };
  } else {
    dataSet = {
      documentTitle: "FragMo",
      emailPlaceholder: "email@metheore.com",
      primaryColor: "#E79300",
      secondaryColor: "#E79300"
    };
  }
}
