

















































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Navigation, ISensorsModel, ViewMode } from "../dashboard.model";
import moment from "moment";
import { getSensorBins } from "@/backend/binId";
import { BinIdResult } from "@/queries/dashboard/BinIdQuery";
import { currentDashboard$ } from "@/backend/dashboard";
import { Exclude } from "node_modules/class-transformer";

@Component
export default class BottomBarResult extends Vue {
  @Prop({ required: true })
  observationType!: Navigation;

  @Prop({ required: true })
  sensorModel!: ISensorsModel;

  @Prop({ required: true })
  viewMode: ViewMode;

  framedTimeDialog = false;
  sliperyTimeDialog = false;
  setBinDialog = false;
  dateRange: Date[] = [];
  sliperyMoment = "";
  currentBin: number | "" = "";
  bins: BinIdResult[] = [];

  async mounted() {
    this.dateRange = this.sensorModel.displayConfig.currentFramedTime;
    this.sliperyMoment = this.sensorModel.displayConfig.currentSliperyHour.toTimeString();
    this.bins = await getSensorBins(this.sensorModel.sensorId);
    const currentBin = this.sensorModel.displayConfig.currentBinId;
    if (this.bins.length === 0) {
      console.warn(`No bins available for sensorId ${this.sensorModel.sensorId}`);
    } else if (currentBin === 0 || currentBin === undefined) {
      this.sensorModel.displayConfig.currentBinId = this.bins[0].binId;
      this.sensorModel.displayConfig.defaultBinId = this.bins[0].binId;
    }
    this.currentBin = this.sensorModel.displayConfig.currentBinId ?? "";
  }

  framedTimeDefault() {
    this.dateRange = this.sensorModel.displayConfig.defaultFramedTime;
    this.framedDatesChanges();
  }

  sliperyTimeDefault() {
    this.sliperyMoment = this.sensorModel.displayConfig.currentSliperyHour.toTimeString();
    this.sliperyDurationChange();
  }
  changeBinId() {
    if (this.currentBin == "") throw new Error();
    this.sensorModel.displayConfig.currentBinId = this.currentBin;
    this.$emit("changeGraphMode", this.viewMode);
  }
  binChanged() {
    if (this.currentBin === "") throw new Error();
    this.changeBinId();
  }
  sliperyDurationChange(): void {
    this.sliperyTimeDialog = false;
    this.sensorModel.displayConfig.currentSliperyHour = new Date(
      "1970-01-01T" + this.sliperyMoment
    );
    this.$emit("changeGraphMode", this.viewMode);
  }

  framedDatesChanges(): void {
    this.framedTimeDialog = false;
    this.sensorModel.displayConfig.currentFramedTime = this.dateRange;
    this.$emit("changeGraphMode", this.viewMode);
  }

  changeGraphMode(mode: ViewMode) {
    this.$emit("changeGraphMode", mode);
  }

  downloadCSV() {
    this.$emit("downloadCSV", null);
  }
}
