export interface IOrderableQuery {
    orderBy?: OrderByConfig;
    orderable: IOrderableProperties;
}

export interface IOrderByDescriptor {
    idx: number;
    fragmentName: string;
}

export class OrderByConfig implements IOrderByDescriptor {
    idx: number;
    fragmentName: string;
    direction: OrderByDirection;
}

export enum OrderByDirection {
    Asc = 0,
    Desc = 1
}

export interface IOrderBySelectorFn<T> {
    (arg: T): IOrderByDescriptor;
}

export interface IOrderableProperties {
    [propertyName: string]: IOrderByDescriptor;
}