const QueryMetadataSymbol = Symbol('QueryMetadata');

interface IQueryMetadata {
    name: string;
}

/**
 * The query decorator let you bind a query class with a query name.
 * @param name The query name
 */
export function Query(name: string) {
    return function(target: any) {
        target[QueryMetadataSymbol] = {name};
        return target;
    }
}

export function readMetadata(query: any): IQueryMetadata {
    const meta: any = query.constructor[QueryMetadataSymbol];
    if (!meta) {
        throw new Error('Could not read the query metadata. The decorator is probably missing');
    }

    return meta;
}

export function readQueryName(query: Record<string, any>): string {
    const meta = readMetadata(query);
    return meta.name;
}
