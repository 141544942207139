






import { Component, Vue } from "vue-property-decorator"
import Backoffice from "@/modules/backoffice/Backoffice.vue"

@Component({
    components: {
        Backoffice
    }
})
export default class DashboardRouter extends Vue{

}
