import { ISensorsModel } from '@/modules/dashboard/dashboard.model';
import { RealtimePercData, RealtimeHistData, TemporalData } from '@/modules/dashboard/signalrService';

export function convertPercDataToCSV(data: RealtimePercData[]): string {
    const columnName = "Date de la mesure;Débit Volumique;P10;P25;P50;P75;P90\n";
    return columnName + data.filter(s => s != null).map(s => s.measureDate + ";" + s.debitVolumique + ";" + s.p10 + ";" + s.p25 + ";" + s.p50 + ";" + s.p75 + ";" + s.p90).join("\n");
}

export function convertHistDataToCSV(data: RealtimeHistData[]) {
    const columnName = "Bin;Moyenne;Nombre de mesures;Minimum;Maximum;Ecart Type;VSI;VSS\n";
    return columnName + data.filter(s => s != null).sort((a, b) => a.valueIndex - b.valueIndex).map(s => s.bin + ";" + s.average + ";" + s.count + ";" + s.min + ";" + s.max + ";" + s.stdev + ";" + s.vsi + ";" + s.vss).join("\n");
}

export function convertTempDataToCSV(data: TemporalData[]) {
  //first find all bin existing
  let binList = new Array<string>();
  data.forEach(elm => {
    elm.values.forEach( val => {
      if (binList.findIndex( bin => bin === val.bin) === -1) {
        binList.push(val.bin);
      }
    });
  });
  binList = binList.sort( (a: string, b: string) => +a < +b ? -1 : 1 );

  // then build the column name with those bins
  const columnName = 'Date de la mesure;' + binList.map( bin => bin).join(';') + '\n';
  // do the thing
  const result = data.map( tempData => {
    const line = binList.map( bin => {
      return tempData.values.find( elm => elm.bin === bin)?.value.toString() ?? "-";
    }).join(';');
    return `${tempData.chunkDate};${line}`;
  }).join('\n')

  return columnName + result;
}

export function downloadCSV(data: string, sensor: ISensorsModel) {
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + data);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);

    if( isNameQualified( sensor ))
    {
        link.setAttribute("download", sensor.productionSiteName + '-' + sensor.sensorName + ".csv");
    }
    document.body.appendChild(link);
    link.click();
    // window.open(encodedUri);
}

interface NameQualified {
    productionSiteName: string;
}

function isNameQualified( o: {} ): o is NameQualified {
    return !!((o as NameQualified).productionSiteName);
}
