




































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IDisplayConfig, ISensorsModel, ViewMode } from "../dashboard.model";
import HeaderResult from "./HeaderResult.vue";
import BottomBarResult from "./BottomBarResult.vue";
import PercentileChart from "../charts/PercentileChart.vue";
import VolumeChart from "../charts/VolumeChart.vue";
import { perc, PercCommand } from "@/commands/graph/PercCommand";
import moment from "moment";
import { registerPercCallback, RealtimePercData } from "../signalrService";
import { convertPercDataToCSV, downloadCSV } from "@/backend/csvService";
@Component({
  components: {
    HeaderResult,
    BottomBarResult,
    PercentileChart,
    VolumeChart,
  },
})
export default class PercSensor extends Vue {
  @Prop({ required: true })
  sensorModel!: ISensorsModel;
  unregister: undefined | (() => void);

  @Prop({ required: true })
  fullscreen: number | null;

  sensorData: RealtimePercData[] = [];
  dateBegin: Date = new Date();
  dateEnd: Date = new Date();

  @Prop({ required: true })
  currentMode: Exclude<ViewMode, ViewMode.RealTime>;

  currentDisplayConfig: IDisplayConfig = this.sensorModel.displayConfig;

  downloadCSV() {
    const csv = convertPercDataToCSV(this.sensorData);
    downloadCSV(csv, this.sensorModel);
  }

  async reloadLiveFeed() {
    this.callUnregister();
    if(this.sensorModel.displayConfig.currentBinId === undefined) return;
    const today = new Date();
    const time = this.sensorModel.displayConfig.currentSliperyHour;
    const slipperyDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    );
    const callback = (s: any) => {
      if (this.currentMode === ViewMode.Slipery) {
        this.sensorData = s;
        this.dateEnd = new Date();
        this.dateBegin = slipperyDate;
      }
    };
    this.addUnregister(await registerPercCallback(
      this.sensorModel.sensorId,
      this.sensorModel.displayConfig.currentBinId,
      slipperyDate,
      this.sensorModel.displayConfig.minVolume,
      this.sensorModel.displayConfig.maxVolume,
      0,
      callback.bind(this)
    ));
  }

  @Watch("sensorModel", { deep: true })
  async refreshOnConfigChange() {
    this.graphModeChanged(this.currentMode);
  }

  mounted() {
    this.refreshOnConfigChange();
  }

  beforeDestroy() {
    this.callUnregister();
  }

  addUnregister( unregister: () => void) {
    if (this.unregister != undefined) this.unregister();
    this.unregister = unregister;
  }

  callUnregister() {
    if (this.unregister != undefined) this.unregister();
    this.unregister = undefined;
    this.sensorData = [];
  }

  async graphModeChanged(mode: Exclude<ViewMode, ViewMode.RealTime>) {
    const loader = this.$loading({fullscreen: true, background: 'rgba(0, 0, 0, 0.7)'});
    const previousMode = this.currentMode;
    this.currentDisplayConfig = this.sensorModel.displayConfig;
    this.callUnregister();
    this.$emit("changeGraphMode", mode);

    let attempt = 1;
    while (attempt <= 5) {
      try {
        switch (mode) {
          case "slipery":
            await this.reloadLiveFeed();
            break;
          case "fixed":
            {
              const cfg = this.sensorModel.displayConfig;
              this.dateBegin = cfg.currentFramedTime[0];
              this.dateEnd = cfg.currentFramedTime[1];
              if(this.sensorModel.displayConfig.currentBinId === undefined) return;
              const res = await perc(
                new PercCommand(
                  cfg.currentFramedTime[0],
                  cfg.currentFramedTime[1],
                  this.sensorModel.displayConfig.currentBinId,
                  this.sensorModel.sensorId,
                  cfg.minVolume,
                  cfg.maxVolume,
                  this.computeResolution(
                    cfg.currentFramedTime[0],
                    cfg.currentFramedTime[1]
                  )
                )
              );
              this.sensorData = res.result.values;
            }
            break;
          default:
            break;
        }
        attempt = 6; // success: no need to attempt again
      } catch (e) {
        console.error(`Attempt ${attempt} failed`);
        console.error(e);
        attempt++;
        if (attempt > 5) {
          this.$message({ type: 'error', message: 'Erreur, veuillez réessayer'});
          this.$emit("changeGraphMode", previousMode);
        }
      }
    }
    loader.close();
  }

  changeFullscreenStatus(event: number | null) {
    this.$emit("to-fullscreen", event);
  }

  computeResolution(start: Date, end: Date): 0 | 1 | 2 {
    const days = moment(end).diff(start, "days");
    if (days < 5) {
      //configurable ?
      return 0;
    }
    if (days < 60) {
      return 1;
    }
    return 2;
  }
}
