



































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BinIdResult } from "@/queries/dashboard/BinIdQuery";
import { ISensorsModel } from "../dashboard/dashboard.model";
import { getDashboardGrantLevel } from "@/backend/dashboard";
import { getUser, promoteAdmin, destroy } from "@/backend/user";
import BackofficeBin from "./BackofficeBin.vue";
import { DashboardGrantLevelQueryResult } from "@/queries/dashboard/DashboardGrantLevelQuery";
import {
  grantUserDashboards,
  GrantUserDashboardsCommand,
} from "@/commands/user/GrantUserDashboardsCommand";
import { UserResult } from "@/queries/user/UserQuery";
@Component
export default class BackofficeUser extends Vue {
  @Prop({ required: true })
  userId: number;
  deleted = false;
  user: UserResult | null = null;
  grantLevels: DashboardGrantLevelQueryResult[] = [];
  grants = [
    {
      value: 0,
      name: "Pas d'accès",
    },
    {
      value: 16,
      name: "Opérateur",
    },
    {
      value: 64,
      name: "Regleur",
    },
    {
      value: 127,
      name: "Administrateur",
    },
  ];
  @Watch("userId")
  async refresh() {
    this.grantLevels = await getDashboardGrantLevel(this.userId);
    this.user = await getUser(this.userId);
  }

  mounted() {
    this.refresh();
  }

  async grantUser() {
    for (let i = 0; i < this.grantLevels.length; i++) {
      const element = this.grantLevels[i];
      grantUserDashboards(
        new GrantUserDashboardsCommand(
          this.userId,
          element.dashboardId,
          element.grantLevel
        )
      );
    }
  }

  async promote() {
    await promoteAdmin(this.userId);
  }

  async destroyUser() {
    await destroy(this.userId);
    this.deleted = true;
    this.refresh();
  }
}
