
































import { Component, Vue, Prop } from "vue-property-decorator";
import { changePasswordWithToken, ChangePasswordWithTokenCommand} from "@/commands/user/ChangePasswordWithTokenCommand";
import { CommandResultStatus } from "../crs/crs";

@Component
export default class ChangePassword extends Vue {
  @Prop({required: true})
  token: string;

  model = {
    password: '',
    passwordCheck: ''
  };

  passwordValidator = (rule: any, value: string, callback: any) => {
    if(value.length < 6)
      callback(new Error('Le mot de passe n\'est pas assez long'));
    else
      callback();
  }
  passwordCheckValidator = (rule: any, value: string, callback: any) => {
    if(value !== this.model.password)
      callback(new Error('Les mots de passe sont différents'));
    else
      callback();
  }

  rules = {
    password: [
      { required: true, message: 'Veuillez entrer un mot de passe', trigger: 'blur' },
      { validator: this.passwordValidator, trigger: 'blur'}
    ],
    passwordCheck: [
      { required: true, message: 'Veuillez entrer un mot de passe', trigger: 'blur' },
      { validator: this.passwordCheckValidator, trigger: 'blur' }
    ]
  };
  loading = false;


  submit(event?: Event) {
    this.loading = true;
    if (event) event.preventDefault();
    const form = this.$refs["form"] as Vue & {
      validate: (cb: (validate: boolean) => Promise<void>) => void;
    };
    form.validate(async (valid: boolean) => {
      if (valid) {
        const result = await changePasswordWithToken(new ChangePasswordWithTokenCommand(this.token, this.model.password));
        if (result.status == CommandResultStatus.Ok) {
          this.$message({ type: 'success', message: 'Mot de passe modifié avec succès, vous allez être redirigé.', duration: 5000});
          setTimeout( () => {
            this.$router.replace('/anonymous');
          }, 5000 )
        } else {
          this.$message({ type: 'error', message: 'Ce lien n\'est plus valide veuillez en regénérer un'});
        }
      }
      this.loading = false;
    });
  }
}
