


































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  IDisplayConfig,
  IProductionSiteModel,
} from "../dashboard/dashboard.model";
import {
  createBelt,
  CreateBeltCommand,
} from "../../commands/productionSite/CreateBeltCommand";
import BackofficeSensor from "./BackofficeSensor.vue";
@Component({
  components: {
    BackofficeSensor,
  },
})
export default class BackofficeProductionSite extends Vue {
  @Prop({ required: true })
  productionSite: IProductionSiteModel;
  newSensorName = "";
  selectedSensorIndex: number | "" = "";

  @Watch("productionSite")
  refresh() {
    this.selectedSensorIndex = "";
  }

  async beltCreate() {
    const res = await createBelt(
      new CreateBeltCommand(
        this.productionSite.productionSiteId,
        this.newSensorName
      )
    );
    this.productionSite.sensors.push({
      sensorId: res.result,
      sensorName: this.newSensorName,
      storageThreshold: 0.3,
      config: "",
      displayConfig: {} as IDisplayConfig,
    });
  }
}
