import { render, staticRenderFns } from "./TempSensor.vue?vue&type=template&id=139eb3a2&scoped=true&"
import script from "./TempSensor.vue?vue&type=script&lang=ts&"
export * from "./TempSensor.vue?vue&type=script&lang=ts&"
import style0 from "./TempSensor.vue?vue&type=style&index=0&id=139eb3a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "139eb3a2",
  null
  
)

export default component.exports