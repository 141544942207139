








import { Component, Vue } from 'vue-property-decorator';
import { dataSet } from './modules/dataSet';

@Component
export default class App extends Vue {
  mounted() {
    document.title = dataSet.documentTitle;
  }

  setVariables() {
    return {
      '--primaryColor': dataSet.primaryColor,
      '--secondaryColor': dataSet.secondaryColor
    }
  }

}
