

























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getAdminBinValues } from "@/backend/binId";
import { BinIdValuesResult } from "@/queries/dashboard/BinIdValuesQuery";
import {
  setBinValues,
  SetBinValuesCommand,
} from "../../commands/productionSite/SetBinValuesCommand";

@Component
export default class BackofficeBin extends Vue {
  @Prop({ required: true })
  binId: number;

  binValues: BinIdValuesResult[] = [];
  binEntryToAdd: BinIdValuesResult = {
    binId: 0,
    valueIndex: 0,
    bin: 0,
    vss: 0,
    vsi: 0,
    showGraph: true
  };

  @Watch("binId")
  async refresh() {
    this.binValues = await getAdminBinValues(this.binId);
  }
  async mounted() {
    this.refresh();
  }

  addEntry() {
    this.binValues.push(this.binEntryToAdd);
    this.binEntryToAdd = {
      binId: 0,
      valueIndex: 0,
      bin: 0,
      vss: 0,
      vsi: 0,
      showGraph: true
    };
  }
  deleteBinEntry(index: number) {
    this.binValues.splice(index, 1);
  }
  async saveBin() {
    await setBinValues(
      new SetBinValuesCommand(
        this.binId,
        this.binValues.map((s) => s.bin),
        this.binValues.map((s) => s.vss),
        this.binValues.map((s) => s.vsi),
        this.binValues.map((s) => s.showGraph)
      )
    );
  }
}
