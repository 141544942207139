








import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { GraphType } from "./graph.model";
import Echarts from "./Echarts.vue";
import { RealtimeHistData } from "../signalrService";

@Component({
  components: {
    Echarts,
  },
})
export default class AccruedChart extends Vue {
  @Prop({ required: true })
  graphType!: GraphType;

  @Prop({ required: true })
  isExpanding!: boolean;

  @Prop({ required: true })
  fullscreen!: boolean;

  @Prop({ required: true })
  sensorData!: RealtimeHistData[];

  options() {
    const binList = this.sensorData.map((s) => s.bin);
    return {
      xAxis: {
        data: binList,
        type: "log",
        logBase: 100,
        min: Math.min(...binList),
        max: Math.max(...binList),
        name: "mm",
        minorTick: {
          show: true,
          splitNumber: 10,
          length: 0
        },
        minorSplitLine: {
          show: true,
          lineStyle: {
            color: "#707070",
          },
        },
        nameLocation: "end",
        nameTextStyle: {
          color: "#C2C2C2",
          fontFamily: "RobotoCondensed-Bold",
          fontSize: 14,
        },
        axisLine: {
          lineStyle: {
            color: "#707070",
          },
          onZero: false,
        },
      },
      yAxis: {
        type: "value",
        interval: 25,
        splitNumber: 10,
        min: 0,
        max: 100,
        name: "% passants cumulés",
        nameLocation: "end",
        nameTextStyle: {
          color: "#C2C2C2",
          fontFamily: "RobotoCondensed-Bold",
          fontSize: 14,
          align: 'left'
        },
        axisLine: {
          lineStyle: {
            color: "#707070",
          },
        },
        minorTick: {
          splitNumber: 2
        },
        minorSplitLine: {
          show: true,
          lineStyle: {
            color: "#707070",
          },
        },
        splitLine: {
          lineStyle: {
            color: "#707070",
          },
        },
        nameGap: 10,
        nameRotate: 0
      },
      grid: {
        show: true,
        left: "3%",
        right: 40,
        bottom: "3%",
        top: "10%",
        containLabel: true,
      },
      series: this.getData(),
      color: ["#FFFFFF", "#CF122D", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      tooltip: {
        formatter: (params: Array<any>) => {
          return `Maille : ${params[1].axisValue.toFixed(2)} - Moyenne : ${params[1].data[1].toFixed(2)}`;
        },
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "#FFF05F",
            width: 20,
            opacity: 0.5,
          },
        },
      },
    };
  }

  getData() {
    const xfPlusTrace = {
      name: 'xfPlusTrace',
      seriesName: '123',
      data: this.sensorData.map((s) => [s.bin, 1.25 * s.stdev + s.average]),
      type: "line",
      showSymbol: false,
    };

    const xfMinusTrace = {
      name: 'xfMinusTrace',
      seriesName: '123',
      data: this.sensorData.map((s) => [s.bin, s.average - 1.25 * s.stdev]),
      type: "line",
      showSymbol: false,
    };

    const averageTrace = {
      name: 'averageTrace',
      seriesName: '123',
      data: this.sensorData.map((s) => [s.bin, s.average]),
      type: "line",
      showSymbol: false,
    };

    const vssTrace = {
      name: 'vssTrace',
      seriesName: '123',
      data: this.sensorData.map((s) => [s.bin, s.vss]),
      type: "scatter",
      symbol: "triangle",
      symbolRotate: 180,
    };

    const vsiTrace = {
      name: 'vsiTrace',
      seriesName: '123',
      data: this.sensorData.map((s) => [s.bin, s.vsi]),
      type: "scatter",
      symbol: "triangle",
    };

    return [xfPlusTrace, averageTrace, xfMinusTrace, vsiTrace, vssTrace];
  }

  @Watch("isExpanding")
  @Watch("fullscreen")
  resizeChart(): void {
    (this.$refs.chart as Vue & { resize: () => boolean }).resize();
  }
}
