import { ProductionSiteSensorQuery } from "@/queries/productionSite/ProductionSiteSensorQuery";
import { ProductionSite } from "@/modules/dashboard/dashboardModel";
import { IDisplayConfig } from '@/modules/dashboard/dashboard.model';

export async function getAllProductionSite() {
  const executor = ProductionSiteSensorQuery.getExecutor(
    new ProductionSiteSensorQuery()
  );
  await executor.queryAll(true);
  const productionSiteArray = [];
  for (let i = 0; i < executor.results$.value.length; i++) {
    const ps = new ProductionSite();
    ps.productionSiteId = executor.results$.value[i].productionSiteId;
    ps.name = executor.results$.value[i].name;
    if (!executor.results$.value[i].sensors) {
      ps.sensors = [];
    } else {
      ps.sensors = JSON.parse(executor.results$.value[i].sensors);
      ps.sensors = ps.sensors.map((s: any) => {
        return {
          sensorId: s.SensorId as number,
          sensorName: s.Name as string,
          config: s.Config as string,
          storageThreshold: s.StorageTreshold as number,
          displayConfig: {} as IDisplayConfig
        }
      });
    }
    productionSiteArray.push(ps);
  }
  return productionSiteArray;
}
