import {IDashboardForm, IDashboardSensorConfig, IProductionSiteModel, ISensorsModel} from './dashboard.model';

export class DashboardModel implements IDashboardForm {
    name = 'Nouveau Dashboard';
    dashboardId = 0;
    config: Array<IDashboardSensorConfig> = [{productionSiteId: '', sensorId: ''},{productionSiteId: '', sensorId: ''},{productionSiteId: '', sensorId: ''}];

    constructor();
    constructor(model: IDashboardForm);
    constructor(model?: any) {
        if (model){
            this.name = model.name;
            this.dashboardId = model.dashboardId;
            this.config = model.config;
            while(this.config.length < 3){
                this.config.push({productionSiteId: 0, sensorId: 0});
            }
        }
    }
}

export class ProductionSite implements IProductionSiteModel {
    productionSiteId = 0;
    name = '';
    sensors: ISensorsModel[] = []
}
