import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import { isAuthenticated } from './modules/authentication/authentication';
import { setCurrentDashboardFromId } from './backend/dashboard'

// Page components
import WelcomePage from './pages/WelcomePage.vue'
import AnonymousLayout from './layout/AnonymousLayout.vue';
import LoginPage from './pages/anonymous/LoginPage.vue';
import ResetPasswordPage from '@/pages/anonymous/ResetPasswordPage.vue';
import AuthenticatedLayout from './layout/AuthenticatedLayout.vue';
import DashboardRouter from './pages/DashboardRouter.vue'
import BackofficeRouter from './pages/BackofficeRouter.vue'

// Routes
const router = new VueRouter({
    base: '/',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/', redirect: to =>{
                return '/app'
            }
        },
        {
            path: '/anonymous', component: AnonymousLayout,
            beforeEnter: (to, from, next) => {
                if (isAuthenticated()) {
                    next('/');
                } else {
                    next();
                }
            },
            children: [
                { path: '', component: LoginPage },
                { path: 'reset-password', component: ResetPasswordPage }
            ]
        },
        {
            path: '/app',
            component: AuthenticatedLayout,
            beforeEnter: (to, from, next) => {
                if (isAuthenticated()) {
                    next();
                } else {
                    next(`/anonymous?returnUrl=${to.path}`);
                }
            },
            children: [
                { path: '', component: WelcomePage },
                {
                    path: 'dashboard/:dashboardId',
                    beforeEnter: async (to, from, next) => {
                        const dashboardId = +to.params['dashboardId'];

                        if (dashboardId) {
                            const success = await setCurrentDashboardFromId(dashboardId);
                            if (success) {
                                next();
                                return;
                            }
                        }

                        next('/');
                    },
                    component: DashboardRouter,
                },
                {
                    path: 'admin',
                    component: BackofficeRouter
                },
            ]
        }
    ]
})


export default router;
