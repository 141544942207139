import {
  AuthService,
  IAuthenticationInfo,
  IUserInfo
} from "@signature/webfrontauth";
import { LoginForm } from "./authentication.model";
import { createAuthConfigUsingCurrentHost } from "./AuthServiceClientConfiguration";
import { BehaviorSubject } from "rxjs";
import { CurrentUserProfileQuery, CurrentUserProfile } from "@/queries/user/CurrentUserProfileQuery";
import { appsettings, loadConfig } from '../config/appSettings';
import backendAxios from "@/modules/axios/axios";

let authService: AuthService | undefined;

export const authenticationInfo = new BehaviorSubject<IAuthenticationInfo | null>(
  null
);

export function getAuthenticatedUser() {
  if(authService === undefined) return null;
  return isAuthenticated() ? authService.authenticationInfo.user : null;
}
export function getAuthenticationLevel(): number {
  if(authService === undefined) return 0;
  return isAuthenticated() ? authService.authenticationInfo.level : 0;
}

export const loginIn$ = new BehaviorSubject(false);
export const authenticatedUser$ = new BehaviorSubject(getAuthenticatedUser());
export const authenticationLevel$ = new BehaviorSubject(getAuthenticationLevel());
export const ressourceToken$ = new BehaviorSubject<string>("");
export const userProfile$ = new BehaviorSubject<CurrentUserProfile | null>(null);

export function isAuthenticated() {
  if(authService === undefined) return false;
  return (
    authService.authenticationInfo &&
    authService.authenticationInfo.level > 1 &&
    authService.authenticationInfo.user &&
    authService.authenticationInfo.user.userId > 0
  );
}

export function getAuthInfo() {
  return authenticationInfo.value;
}

export function getUserProfile() {
  return isAuthenticated() ? userProfile$.value : null;
}

export async function refreshUserProfile() {
  if (isAuthenticated()) {
    const u = await CurrentUserProfileQuery.getExecutor(
      new CurrentUserProfileQuery()
    ).queryFirst();
    userProfile$.next(u === undefined ? null : u);
  } else {
    userProfile$.next(null);
  }
}

export async function refreshAuthentication() {
  if(authService === undefined) {
    await loadConfig();
    authService = new AuthService(
      appsettings.AuthService,
      backendAxios
    );
  }
  await authService.refresh().catch();
  
  authService.addOnChange( auth => {
    if (auth.authenticationInfo.level !== authenticationLevel$.value) {
      authenticationLevel$.next(auth.authenticationInfo.level);
    }
  });
  authenticatedUser$.next(getAuthenticatedUser());
  getAuthenticatedUser();
}

export function logout() {
  authenticationInfo.next(null);
  authenticatedUser$.next(null);
  userProfile$.next(null);
  if(authService === undefined) throw new Error();
  return authService.logout();
}

export async function login(model: LoginForm) {
  loginIn$.next(true);
  if(authService === undefined) throw new Error();
  await authService.basicLogin(model.email, model.password, true);
  loginIn$.next(false);
  if (authService.currentError) throw (authService.currentError);
  authenticatedUser$.next(getAuthenticatedUser());
  return isAuthenticated();
}

export function authenticateRequests() {
  if(authService === undefined) throw new Error();
  backendAxios.interceptors.request.use(config => {
  if(authService === undefined) throw new Error();
  config.headers["Authorization"] = "Bearer " + authService.token;
    return config;
  });
}

export function getBearerToken() {
  if(authService === undefined) throw new Error();
  return authService.token;
}

export function getRessourceToken(): Promise<string> {
  if(authService === undefined) throw new Error();
  return backendAxios
    .get("/.ttoken")
    .then(t => t.data)
    .then(t => {
      return t.token;
    });
}

let ressourceTokenInterval: NodeJS.Timeout | null = null;

export function requestRessourceToken(interval: number) {
  if(authService === undefined) throw new Error();
  if (ressourceTokenInterval) {
    clearInterval(ressourceTokenInterval);
  }

  const getToken = async () => {
    const token = await getRessourceToken();
    ressourceToken$.next(token);
  };

  ressourceTokenInterval = setInterval(getToken, interval);

  getToken();
}
