
















import { Component, Prop, Vue } from "vue-property-decorator";
import { CurrentDashboardSelectedQuery } from "../../queries/dashboard/CurrentDashboardSelectedQuery";
import { currentDashboard$ } from "../../backend/dashboard";
import {
  IDashboardModel,
  Navigation,
  ISensorsModel,
  IDisplayConfig,
  ViewMode,
} from "./dashboard.model";
import PercSensor from "./observation/PercSensor.vue";
import HistSensor from "./observation/HistSensor.vue";
import TempSensor from "./observation/TempSensor.vue";
import SettingsPage from "./observation/SettingsPage.vue";
import moment from "moment";

@Component({
  components: {
    PercSensor,
    HistSensor,
    SettingsPage,
    TempSensor
  },
})
export default class Dashboard extends Vue {
  @Prop({ required: true })
  observationType!: Navigation;

  @Prop({required: true})
  fullscreen: number | null;

  viewMode: ViewMode = ViewMode.Slipery;

  exeDashboard = CurrentDashboardSelectedQuery.getExecutor(
    new CurrentDashboardSelectedQuery(currentDashboard$.value!.dashboardId)
  );

  async mounted() {
    await this.exeDashboard.queryAll();
  }

  graphModeChanged(mode: ViewMode) {
    this.viewMode = mode;
  }

  status(type: Navigation) {
    return type == this.observationType;
  }

  toCamelCase(key: string, value: any) {
    if (value && typeof value === "object") {
      for (const k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }

  changeFullscreenStatus(event: number | null) {
    this.$emit('to-fullscreen', event);
  }

  setDefaultValue<T, K extends keyof T>(obj: T, propName: K, defaultVal: T[K]) {
    if (obj[propName] === undefined) obj[propName] = defaultVal;
  }
  get getDashboard(): IDashboardModel {
    const value = this.exeDashboard.results$.value;
    const dashboard: IDashboardModel = {
      dashboardId: 0,
      name: "",
      config: { defaultViewMode: ViewMode.Slipery },
      sensorConfig: [],
      binId: 0,
      sensorId: 0,
    };
    value.forEach((item) => {
      dashboard.dashboardId = item.dashboardId;
      dashboard.name = item.name;
      dashboard.config = item.config ? JSON.parse(item.config, this.toCamelCase) : dashboard.config;
      const arr: ISensorsModel[] = JSON.parse(item.sensors, this.toCamelCase);
      if (arr === null) return;
      arr.forEach((s) => {
        if ((s.displayConfig as any) === "") {
          s.displayConfig = {} as IDisplayConfig;
        } else {
          s.displayConfig = JSON.parse(s.displayConfig as any);
        }
        const cfg = s.displayConfig;
        if (
          isNaN(Date.parse((cfg.defaultRealtimeDuration as any) as string)) ||
          cfg.defaultRealtimeDuration === null
        ) {
          cfg.defaultRealtimeDuration = (undefined as any) as Date;
        }
        if (
          isNaN(Date.parse((cfg.currentRealtimeDuration as any) as string)) ||
          cfg.defaultRealtimeDuration === null
        ) {
          cfg.currentRealtimeDuration = (undefined as any) as Date;
        }

        /*
        ** We only need time for realtimeDuration and sliperyHour
        ** So we make sure to get rid of the date
        */
        if (cfg.defaultRealtimeDuration !== undefined) {
          const fullDate = new Date(cfg.defaultRealtimeDuration);
          cfg.defaultRealtimeDuration = new Date(0);
          cfg.defaultRealtimeDuration.setUTCHours(fullDate.getUTCHours());
          cfg.defaultRealtimeDuration.setUTCMinutes(fullDate.getUTCMinutes());
          cfg.defaultRealtimeDuration.setUTCSeconds(fullDate.getUTCSeconds());
        }
        if (cfg.currentRealtimeDuration !== undefined) {
          const fullDate = new Date(cfg.currentRealtimeDuration);
          cfg.currentRealtimeDuration = new Date(0);
          cfg.currentRealtimeDuration.setUTCHours(fullDate.getUTCHours());
          cfg.currentRealtimeDuration.setUTCMinutes(fullDate.getUTCMinutes());
          cfg.currentRealtimeDuration.setUTCSeconds(fullDate.getUTCSeconds());
        }
        if (cfg.defaultSliperyHour !== undefined) {
          const fullDate = new Date(cfg.defaultSliperyHour);
          cfg.defaultSliperyHour = new Date(0);
          cfg.defaultSliperyHour.setUTCHours(fullDate.getUTCHours());
          cfg.defaultSliperyHour.setUTCMinutes(fullDate.getUTCMinutes());
          cfg.defaultSliperyHour.setUTCSeconds(fullDate.getUTCSeconds());
        }

        if (cfg.currentSliperyHour !== undefined) {
          const fullDate = new Date(cfg.currentSliperyHour);
          cfg.currentSliperyHour = new Date(0);
          cfg.currentSliperyHour.setUTCHours(fullDate.getUTCHours());
          cfg.currentSliperyHour.setUTCMinutes(fullDate.getUTCMinutes());
          cfg.currentSliperyHour.setUTCSeconds(fullDate.getUTCSeconds());
        }

        this.setDefaultValue(
          cfg,
          "defaultRealtimeDuration",
          new Date("1970-01-01T03:00:00")
        );
        this.setDefaultValue(
          cfg,
          "currentRealtimeDuration",
          cfg.defaultRealtimeDuration
        );
        this.setDefaultValue(cfg, "defaultFramedTime", [
          moment().subtract(1, "day").toDate(),
          new Date(),
        ]);
        this.setDefaultValue(cfg, "currentFramedTime", cfg.defaultFramedTime);
        this.setDefaultValue(cfg, "thresholdHistCumul", 15);
        this.setDefaultValue(
          cfg,
          "defaultSliperyHour",
          new Date(0, 0, 0, 6, 0, 0, 0)
        );
        this.setDefaultValue(cfg, "currentSliperyHour", cfg.defaultSliperyHour);
        this.setDefaultValue(cfg, "density", 0);
        this.setDefaultValue(cfg, "useWeight", false);
        this.setDefaultValue(cfg, "minVolume", -1);
        this.setDefaultValue(cfg, "maxVolume", -1);
      });
      dashboard.sensorConfig = arr;
    });
    this.viewMode = dashboard.config.defaultViewMode;
    return dashboard;
  }
}
