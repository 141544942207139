import { HubConnectionBuilder } from '@microsoft/signalr'
import { appsettings } from '../config/appSettings';
import { getBearerToken } from '../authentication/authentication';
import moment from "moment"

export async function registerHistRealtimeCallback(sensorId: number, binId: number, duration: Date, minVolume: number, maxVolume: number, callback: (data: RealtimeHistData[]) => void): Promise<() => void> {
    if (binId as any === "") throw Error();
    const secsDuration = moment(duration).diff(moment(new Date(0)), 'seconds');
    if(isNaN(secsDuration)) throw new Error("Invalid duration.");
    const connection = new HubConnectionBuilder()
        .withUrl(
            appsettings.SignalRServer + `/realtime/realtime-hist-cumul?SensorId=${sensorId.toString()}&BinId=${binId.toString()}&Duration=${secsDuration}&MinVolume=${minVolume}&MaxVolume=${maxVolume}`,
            { accessTokenFactory: getBearerToken })
        .build();

    await connection.start();
    connection.on("SendHistCumulAsync", callback);
    return () => { connection.stop(); };
}

export async function registerHistSlipperyCallback(sensorId: number, binId: number, beginningDate: Date, minVolume: number, maxVolume: number, callback: (data: RealtimeHistData[]) => void): Promise<() => void> {
    if (binId as any === "") throw Error();
    const connection = new HubConnectionBuilder()
        .withUrl(appsettings.SignalRServer + `/realtime/slippery-hist-cumul?SensorId=${sensorId.toString()}&BinId=${binId.toString()}&BeginningDate=${beginningDate.toISOString()}&MinVolume=${minVolume}&MaxVolume=${maxVolume}`,
            { accessTokenFactory: getBearerToken })
        .build();

    await connection.start();
    connection.on("SendHistCumulAsync", callback);
    return () => { connection.stop(); };
}

export class RealtimeHistData {
    average: number;
    count: number;
    bin: number;
    max: number;
    min: number;
    stdev: number;
    valueIndex: number;
    vsi: number;
    vss: number;
}

export async function registerPercCallback(sensorId: number, binId: number, beginDate: Date, minVolume: number, maxVolume: number, resolution: number, callback: (date: RealtimePercData[]) => void): Promise<() => void> {
    if (binId as any === "") throw Error();
    const connection = new HubConnectionBuilder()
        .withUrl(appsettings.SignalRServer + `/realtime/realtime-perc?BinId=${binId.toString()}&SensorId=${sensorId.toString()}&DateStart=${beginDate.toISOString()}&MinVolume=${minVolume}&MaxVolume=${maxVolume}&Resolution=${resolution}`)
        .build();
    await connection.start();
    connection.on("SendPercAsync", callback);
    return () => { connection.stop(); };
}

export class RealtimePercData {
    measureDate: Date;
    p10: number;
    p25: number;
    p50: number;
    p75: number;
    p90: number;
    debitVolumique: number;
}

export class TemporalData {
    chunkDate: Date;
    values: Array<{bin: string; value: number}>;
}

export async function registerTempSlipperyCallback(sensorId: number, binId: number, beginningDate: Date, minVolume: number, maxVolume: number, resolution: number, callback: (data: TemporalData[]) => void): Promise<() => void> {
  if (binId as any === "") throw Error();
    const connection = new HubConnectionBuilder()
        .withUrl(appsettings.SignalRServer + `/slippery/slippery-temp?BinId=${binId.toString()}&SensorId=${sensorId.toString()}&DateStart=${beginningDate.toISOString()}&MinVolume=${minVolume}&MaxVolume=${maxVolume}&Resolution=${resolution}`)
        .build();
    await connection.start();
    connection.on("SendTempAsync", callback);
    return () => { connection.stop(); };
}