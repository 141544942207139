



























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { BinIdResult } from "@/queries/dashboard/BinIdQuery";
import { ISensorsModel } from "../dashboard/dashboard.model";
import { getAdminSensorBins } from "@/backend/binId";
import {
  createBin,
  CreateBinCommand,
} from "@/commands/productionSite/CreateBinCommand";
import {
  setSensorStorageThreshold,
  SetSensorStorageThresholdCommand,
} from "@/commands/productionSite/SetSensorStorageThresholdCommand";
import BackofficeBin from "./BackofficeBin.vue";
import {
  binDestroy,
  BinDestroyCommand,
} from "../../commands/productionSite/BinDestroyCommand";
import { CommandResultStatus } from "../crs/crs";

@Component({
  components: {
    BackofficeBin,
  },
})
export default class BackofficeSensor extends Vue {
  @Prop({ required: true })
  sensor: ISensorsModel;
  newBin: BinIdResult = {
    binId: 0,
    productId: 0,
    originId: 0,
    destinationId: 0,
    name: "",
  };
  binListPromiseId = 0;
  currentBinList: BinIdResult[] = [];
  selectedBinId: number | "" = "";
  currentBinPromiseId = 0;
  newThreshold = 0;
  showDestroyConfirmation = false;

  @Watch("sensor")
  async refresh() {
    this.newThreshold = this.sensor.storageThreshold;
    this.currentBinList = [];
    this.currentBinList = await getAdminSensorBins(this.sensor.sensorId);
  }

  mounted() {
    this.refresh();
  }

  get currentBin() {
    return this.currentBinList.find((s) => s.binId == this.selectedBinId);
  }
  async createNewBin() {
    const res = await createBin(
      new CreateBinCommand(
        this.sensor.sensorId,
        this.newBin.destinationId,
        this.newBin.originId,
        this.newBin.productId,
        this.newBin.name
      )
    );
    this.currentBinList.push({
      binId: res.result,
      productId: this.newBin.productId,
      originId: this.newBin.originId,
      destinationId: this.newBin.destinationId,
      name: this.newBin.name,
    });
  }

  async setStorageThreshold() {
    const res = await setSensorStorageThreshold(
      new SetSensorStorageThresholdCommand(
        this.sensor.sensorId,
        this.newThreshold
      )
    );
    this.sensor.storageThreshold = this.newThreshold;
  }

  async destroyBin() {
    try {
      const res = await binDestroy(
        new BinDestroyCommand(
          this.selectedBinId as number
        )
      );

      if (res.status === CommandResultStatus.Ok) {
        this.$message({ type: 'success', message: 'Bin supprimé avec succès'});
        const idx = this.currentBinList.findIndex(bin => bin.binId === this.selectedBinId);
        this.currentBinList.splice(idx, 1);
        this.selectedBinId = "";
      } else {
        this.$message({ type: 'error', message: 'Vous ne pouvez pas supprimer un bin utilisé dans un tableau'});
      }
    } catch (e) {
      this.$message({ type: 'error', message: 'Erreur serveur'});
    } finally {
      this.showDestroyConfirmation = false;
    }
  }
}
