export interface ICurrentDashboard {
  name: string;
  dashboardId: number;
  grantLevel: number;
}

export interface IDashboardNB {
  dashboardId: number;
  name: string;
  createDate: Date;
  nbSensor: number;
}

export interface IDashboardModel {
  name: string;
  dashboardId: number;
  config: IDashboardConfig;
  sensorConfig: Array<ISensorsModel>;
  binId: number;
  sensorId: number;
}

export interface IDashboardForm {
  name: string;
  dashboardId: number;
  config: Array<IDashboardSensorConfig>;
}

export interface IFeatureAcl {
  //featureTypeId: number;
  grantLevel: number;
}

export interface IDashboardConfig {
    defaultViewMode: ViewMode;
}

export interface IDashboardSensorConfig {
  productionSiteId: number | '';
  sensorId: number | '';
}

export interface IProductionSiteModel {
  productionSiteId: number;
  name: string;
  sensors: ISensorsModel[];
}

export interface ISensorsModel {
  sensorId: number;
  sensorName: string;
  config: string;
  storageThreshold: number;
  displayConfig: IDisplayConfig;
}

export interface IDisplayConfig {
  defaultRealtimeDuration: Date;
  currentRealtimeDuration: Date;
  defaultFramedTime: Date[];
  currentFramedTime: Date[];
  thresholdHistCumul: number;
  currentSliperyHour: Date;
  defaultSliperyHour: Date;
  useWeight: boolean;
  density: number;
  minVolume: number;
  maxVolume: number;
  defaultBinId: number;
  currentBinId?: number;
}

export enum Navigation {
  None = "",
  Dashboard = "dashboard",
  Accrued = "accrued",
  Percentile = "perc",
  Temporal = 'temporal',
  Shape = "shape",
  Alarm = "alarm",
  Setting = "setting"
}

export const navName = {
  [Navigation.Accrued]: "Courbe Cumulée",
  [Navigation.Percentile]: "Percentiles",
  [Navigation.Dashboard]: "Dashboard",
  [Navigation.Temporal]: "Courbe Temporelle",
  [Navigation.Shape]: "Formes",
  [Navigation.Alarm]: "Alarmes",
  [Navigation.Setting]: "Paramètrages"
}

export enum ViewMode {
  Slipery = "slipery",
  Fixed = "fixed",
  RealTime = "realtime"
}
