






import { Component, Prop, Vue } from "vue-property-decorator";
import Echarts from "./Echarts.vue";
import { EChartsOptionConfig } from "echarts";
import { RealtimePercData } from "../signalrService";
import moment from "moment";
import { IDisplayConfig } from "../dashboard.model";

@Component({
  components: {
    Echarts,
  },
})
export default class VolumeChart extends Vue {
  @Prop({ required: true })
  sensorData!: (RealtimePercData | null)[];

  @Prop({ required: true })
  displayConfig!: IDisplayConfig;

  @Prop({
    required: true,
  })
  dateBegin: Date;
  @Prop({
    required: true,
  })
  dateEnd: Date;

  options() {
    return {
      xAxis: {
        type: "time",
        axisLine: {
          lineStyle: {
            color: "#707070",
          },
        },
        min: this.dateBegin,
        max: this.dateEnd,
      },
      yAxis: {
        type: "value",
        name: this.displayConfig.useWeight ? 'Débit massique (T/h)' : 'Débit volumique (m3/h)',
        nameGap: 15,
        nameLocation: "end",
        nameTextStyle: {
          color: "#C2C2C2",
          fontFamily: "RobotoCondensed-Bold",
          fontSize: 14,
          align:"left"
        },
        axisLine: {
          lineStyle: {
            color: "#707070",
          },
        },
      },
      grid: {
        show: true,
        top: "13%",
        left: "4%",
        right: "2.5%",
        bottom: "3%",
        containLabel: true,
      },
      series: this.getData(),
      color: ["#69C3CD"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "#FFF05F",
            width: 2,
            opacity: 0.5,
          },
        },
        formatter: function(params: any) {
          const colorSpan = (color: string) => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
          let result = moment(params[0].axisValue).format('HH:mm MM-DD');
          for (let i = 0; i < params.length; i++) {
            result += '<br/>' + colorSpan(params[i].color) + params[i].data[1].toFixed(2);
          }
          return result;
        },
      },
    } as EChartsOptionConfig;
  }
  getData() {
    const data = [];
    for (let i = 0; i < this.sensorData.length; i++) {
      const s = this.sensorData[i];
      if (s === null) {
        data.push(null);
      } else {
        data.push([
          s.measureDate,
          this.displayConfig.useWeight
            ? s.debitVolumique * this.displayConfig.density * 360
            : s.debitVolumique * 360,
        ]);
      }
    }
    return [
      {
        data: data,
        showSymbol: false,
        type: "line",
        connectNulls: false,
      },
    ];
  }
}
