






import { Component, Vue } from "vue-property-decorator"
import Login from '@/modules/authentication/Login.vue';

@Component({
    components: {
        Login
    }
})
export default class LoginPage extends Vue {

}
