






import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Echarts from "./Echarts.vue";
import moment from "moment";
import { EChartsOptionConfig } from "echarts";
import { RealtimePercData } from "../signalrService";

@Component({
  components: {
    Echarts,
  },
})
export default class PercentileChart extends Vue {
  @Prop({
    required: true,
  })
  sensorData!: (RealtimePercData | null)[];
  @Prop({
    required: true,
  })
  dateBegin: Date;
  @Prop({
    required: true,
  })
  dateEnd: Date;
  @Prop({
    required: false
  })
  resfresh: boolean;

  @Watch("dateBegin")
  @Watch("dateEnd")
  options() {
    return {
      xAxis: {
        type: "time",
        axisLine: {
          lineStyle: {
            color: "#707070",
          },
        },
        min: this.dateBegin,
        max: this.dateEnd,
      },
      yAxis: {
        type: "value",
        min: 0,
        name: "Taille (mm)",
        nameGap: 15,
        nameLocation: "end",
        nameTextStyle: {
          color: "#C2C2C2",
          fontFamily: "RobotoCondensed-Bold",
          fontSize: 14,
          align:"left"
        },
        axisLine: {
          lineStyle: {
            color: "#707070",
            width: 0.5,
          },
        },
      },
      grid: {
        show: true,
        top: "15%",
        left: "4%",
        right: "2.5%",
        height: "86%",
        containLabel: true,
      },
      series: this.getData(),
      color: ["#737373", "#E89103", "#C91527", "#E89103", "#737373"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "#FFF05F",
            width: 2,
            opacity: 0.5,
          },
        },
        formatter: function(params: any) {
          const colorSpan = (color: string) => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
          let result = moment(params[0].axisValue).format('HH:mm MM-DD');
          for (let i = 0; i < params.length - 1; i++) { // We skip the last element which is present to get a better visual chart
            result += '<br/>' + colorSpan(params[i].color) + params[i].data[1].toFixed(2);
          }
          return result;
        },
      },
    } as EChartsOptionConfig;
  }

  @Watch("sensorData", { deep: true })
  getData() {
    const RemoveBottomAreaTrick = {
      z: -1,
      data: this.getArray("p10"),
      showSymbol: false,
      areaStyle: {
        color: "rgb(54, 54, 54)",
        opacity: 1,
      },
      lineStyle: {
        opacity: 0,
      },
      type: "line",
      connectNulls: true,
      label: {
        show: false,
            valueFormatter: (value: number) => 'qkljfsqdlkf',
      },
      valueFormatter: (value: number) => 'qkljfsqdlkf',
      tooltip: {
        valueFormatter: (value: number) => 'qkljfsqdlkf',
        label: {
          valueFormatter: (value: number) => 'qkljfsqdlkf',
        }
      }
    };
    const P10 = {
      z: -2,
      data: this.getArray("p10"),
      showSymbol: false,
      type: "line",
    };
    const P25 = {
      z: -2,
      data: this.getArray("p25"),
      showSymbol: false,
      areaStyle: {
        color: "rgb(115, 115, 115)",
        opacity: 1,
      },
      type: "line",
    };
    const P50 = {
      z: -2,
      data: this.getArray("p50"),
      showSymbol: false,
      type: "line",
    };
    const P75 = {
      z: -2,
      data: this.getArray("p75"),
      showSymbol: false,
      areaStyle: {
        color: "rgb(232, 145, 3)",
        opacity: 1,
      },
      type: "line",
    };

    const P90 = {
      z: -2,
      data: this.getArray("p90"),
      showSymbol: false,
      areaStyle: {
        color: "rgba(115, 115, 115)",
        opacity: 1,
      },
      type: "line",
    };
    return [RemoveBottomAreaTrick, P10, P25, P50, P75, P90].reverse();
  }

  getArray<T extends keyof RealtimePercData>(keyname: T) {
    const tab = [];
    for (let i = 0; i < this.sensorData.length; i++) {
      const s = this.sensorData[i];
      if (s === null) {
        tab.push(null);
      } else {
        tab.push([s.measureDate, s[keyname]]);
      }
    }
    return tab;
  }
}
