


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  IDashboardForm,
  IProductionSiteModel,
  IDashboardNB,
} from "./dashboard.model";
import { saveDashboard } from "../../backend/dashboard";
import { getAllProductionSite} from "../../backend/productionSite";
@Component
export default class DashboardForm extends Vue {
  @Prop({
    required: true,
  })
  dashboardEdit!: boolean;

  @Prop({
    required: true,
  })
  dashboardModel!: IDashboardForm;

  @Prop({
    required: true,
  })
  dashboardList!: Array<IDashboardNB>;

  productionSiteArray: IProductionSiteModel[] = [];

  async mounted() {
    this.productionSiteArray = await getAllProductionSite();
  }

  cancel() {
    this.onSaveOrCancel();
  }

  async save() {
    await saveDashboard(this.dashboardModel.dashboardId, this.dashboardModel);
    this.$emit("add-dashboard");
    this.onSaveOrCancel();
  }

  onSaveOrCancel() {
    this.$emit("save-or-cancel", false);
  }

  getSensorList(siteId: number) {
    if (!siteId) {
      return undefined
    } else {
      return this.productionSiteArray
        .filter((element) => element.productionSiteId == siteId)
        .flatMap((sensor) => sensor.sensors);
    }
  }


  disableSlot(id: number) {
    if (this.dashboardModel.config[id].productionSiteId) {
      return false;
    }
    return true;
  }
}
