



















































import { UpdateDashboardCommand } from "@/commands/dashboard/UpdateDashboardCommand";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDashboardModel, ISensorsModel, ViewMode } from "../dashboard.model";
import SensorSettings from "./SensorSettings.vue";
import {
  UpdateDashboardConfigCommand,
  updateDashboardConfig
} from "@/commands/dashboard/UpdateDashboardConfigCommand";
import { CommandResultStatus } from "@/modules/crs/crs";
@Component({
  components: {
    SensorSettings,
  },
})
export default class SettingsPage extends Vue {
  @Prop({ required: true })
  dashboardModel!: IDashboardModel;
  selected: number | "" = "";
  viewMode = ViewMode;
  selectedView = ViewMode.Slipery;

  mounted() {
    this.selectedView = this.dashboardModel.config.defaultViewMode;
  }

  public async updateConfig() {
    this.dashboardModel.config.defaultViewMode = this.selectedView;
    const result = await updateDashboardConfig(
                    new UpdateDashboardConfigCommand(
                      this.dashboardModel.dashboardId,
                      JSON.stringify(this.dashboardModel.config)
                    )
                  );

    if (result.status === CommandResultStatus.Ok)
      this.$message({ message: 'Paramètres enregistrés avec succès', type: 'success', center: true });
    else
      this.$message({ message: 'Erreur, paramètres non-enregistrés', type: 'error', center: true });
  }

}
