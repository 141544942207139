import { AllUserQuery } from "../queries/user/AllUserQuery"
import { UserQuery } from "../queries/user/UserQuery"
import { promoteUserToAdmin, PromoteUserToAdminCommand } from "@/commands/user/PromoteUserToAdminCommand";
import { destroyUser, DestroyUserCommand } from "@/commands/user/DestroyUserCommand";
import { CommandResultStatus } from '@/modules/crs/crs';
export async function getAllUsers() {
  const ex = AllUserQuery.getExecutor(new AllUserQuery());
  return await ex.queryAll(true);
}

export async function getUser(userId: number) {
  const ex = UserQuery.getExecutor(new UserQuery(userId));
  const res = await ex.queryFirst();
  if (res === undefined) throw new Error("User not found.");
  return res;
}

export async function promoteAdmin(userId: number) {
    const res = await promoteUserToAdmin(new PromoteUserToAdminCommand(userId));
    if(res.status !== CommandResultStatus.Ok) throw new Error();
}

export async function destroy(userId: number) {
    const res = await destroyUser(new DestroyUserCommand(userId));
    if(res.status !== CommandResultStatus.Ok) throw new Error();
}
