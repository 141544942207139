

































































































































































































































import { getSensorBins } from "@/backend/binId";
import { BinIdResult } from "@/queries/dashboard/BinIdQuery";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IDisplayConfig, ISensorsModel, ViewMode } from "../dashboard.model";

@Component
export default class HeaderResult extends Vue {
    @Prop({required:true})
    sensor: ISensorsModel

    @Prop({required: true})
    fullscreen: number | null;

    @Prop({required: true})
    observationType: ViewMode

    @Prop({required: true})
    displayConfig: IDisplayConfig

    bins: BinIdResult[] = []

    displayBinName = ''

    async mounted(){
      this.bins = await getSensorBins(this.sensor.sensorId);
    }

    get currentBin() {
      return this.bins.find((bin) => bin.binId == this.sensor.displayConfig.currentBinId);
    }

    @Watch("displayConfig")
    get displayTime() {
      switch (this.observationType) {
        case ViewMode.Fixed:
          return moment(this.sensor.displayConfig.currentFramedTime[0]).format("DD/MM/YYYY HH:mm") + ' au ' + moment(this.sensor.displayConfig.currentFramedTime[1]).format("DD/MM/YYYY HH:mm");
        case ViewMode.RealTime:
          // eslint-disable-next-line no-case-declarations
          const time = moment(this.sensor.displayConfig.currentRealtimeDuration).utc();
          // eslint-disable-next-line no-case-declarations
          let res = '';
          if (time.hours() > 0)
            res = `${time.hours()} h`;
          if (time.minutes() > 0)
            res += ` ${time.minutes()} min`;
          if (time.seconds() > 0)
            res += ` ${time.seconds()} s`;

          return res.trim();
        case ViewMode.Slipery:
          return 'depuis ' + moment(this.sensor.displayConfig.currentSliperyHour).utc().format("HH:mm");
        default: return ''
      }
    }

    @Watch("displayConfig")
    get currentBinName() {
      return this.currentBin?.name ?? '';
    }

    getDate(realDate: string): Date {
      const date = new Date(realDate);
      return date;
    }

    toFullScreen() {
      if (!this.fullscreen)
        this.$emit('to-fullscreen', this.sensor.sensorId);
      else
        this.$emit('to-fullscreen', null);
    }
}
