import { CrsEndpoint } from '@signature/crs-client';
import { appsettings, loadConfig } from '../config/appSettings';
import backendAxios from "@/modules/axios/axios";
export let endpoint: CrsEndpoint;

export async function loadCrs() {
  await loadConfig();
  endpoint = new CrsEndpoint({
    url: appsettings.CrsEndpoint,
    axiosInstance: backendAxios
  });
}

export enum CommandResultStatus {
    Ok = 200,
    Unauthorized = 401,
    BadCommand = 400
}

export class CommandResult<T = any> {
    status: CommandResultStatus;
    result: T;
}

