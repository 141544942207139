





































































































import { Component, Vue} from 'vue-property-decorator'
import { Subscription } from '../modules/utils/rx'
import { userProfile$, logout } from '@/modules/authentication'
import moment from "moment"
import { currentDashboard$ } from '../backend/dashboard';
import { ICurrentDashboard } from '../modules/dashboard/dashboard.model';
import { CurrentUserProfile } from '@/queries/user/CurrentUserProfileQuery';
import http from "axios";

@Component
export default class AuthenticatedLayout extends Vue {
    @Subscription(userProfile$)
    userProfile!: CurrentUserProfile | null;

    @Subscription(currentDashboard$)
    currentDashboard!: ICurrentDashboard | null;

    date: string = moment().format("DD/MM/YYYY");
    time: string = moment().format("HH:mm");
    showAbout = false;
    showASS = false;
    aboutContent: string | null = null;
    assContent: string | null = null;

    async created() {
      const content = await http.get(`/content.json`);
      this.aboutContent = content.data.about;
      this.assContent = content.data.ass;
    }

    mounted() {
        setInterval(() => {
            this.date = moment().format("DD/MM/YYYY");
            this.time = moment().format("HH:mm");
        }, 30000);
    }

    get isAdmin() {
        return this.userProfile && this.userProfile.isAdmin;
    }

    accueil(){
      this.$router.replace('/app');
    }
    backoffice() {
      this.$router.replace('/app/admin');
    }

    toDashboard(id: number){
      this.$router.replace('app/dashboard/' + id);
    }

    async logout() {
        await logout();
        this.$router.replace('/anonymous');
    }

    openAbout() {
      this.showAbout = true;
    }

    openASS() {
      this.showASS = true;
    }
}
