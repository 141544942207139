





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { ICurrentDashboard, Navigation } from "./dashboard.model";
import { currentDashboard$ } from "../../backend/dashboard";
import { Subscription } from '@/modules/utils/rx'

@Component
export default class BottomNavBar extends Vue {
  @Prop({required: true})
  activePage: Navigation;

  @Subscription(currentDashboard$)
  currentDashboard!: ICurrentDashboard | null;

  get isOperator() {
    if(this.currentDashboard === null) return false;
    return this.currentDashboard.grantLevel>=64;
  }

  changePage(observationType: Navigation) {
    this.$emit("observation-type", observationType);
  }

  getClasse(name: string) {
    if (name == this.activePage) {
      return name + " active flexing";
    } else {
      return name + " flexing";
    }
  }
}
