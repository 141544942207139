






import { Component, Prop, Vue } from "vue-property-decorator";
import Echarts from "./Echarts.vue";
import { EChartsOptionConfig } from "echarts";
import { RealtimePercData, TemporalData } from "../signalrService";
import moment from "moment";
import { IDisplayConfig } from "../dashboard.model";
import { forEach } from "node_modules/@types/lodash";

@Component({
  components: {
    Echarts,
  },
})
export default class TemporalChart extends Vue {
  @Prop({ required: true })
  sensorData!: (TemporalData | null)[];

  @Prop({ required: true })
  displayConfig!: IDisplayConfig;

  @Prop({
    required: true,
  })
  dateBegin: Date;
  @Prop({
    required: true,
  })
  dateEnd: Date;

  options() {
    return {
      xAxis: {
        type: "time",
        axisLine: {
          lineStyle: {
            color: "#707070",
          },
        },
        min: this.dateBegin,
        max: this.dateEnd,
      },
      yAxis: {
        type: "value",
        name: "% passants cumulés",
        nameLocation: "end",
        nameTextStyle: {
          color: "#C2C2C2",
          fontFamily: "RobotoCondensed-Bold",
          fontSize: 14,
          align: "left"
        },
        axisLine: {
          lineStyle: {
            color: "#707070",
          },
        },
      },
      grid: {
        show: true,
        top: "11%",
        left: "4%",
        right: "2.5%",
        bottom: "3%",
        containLabel: true,
      },
      series: this.getData(),
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "#FFF05F",
            width: 2,
            opacity: 0.5,
          },
        },
        formatter: function(params: any) {
          const colorSpan = (color: string) => '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + color + '"></span>';
          let result = moment(params[0].axisValue).format('HH:mm MM-DD');
          params = (params as Array<any>).sort( (a: any, b: any) => { return +(a.seriesName) > + (b.seriesName) ? -1 : 1; }); // Sort by descending seriesName
          for (let i = 0; i < params.length; i++) {
            result += `<br/>${colorSpan(params[i].color)} ${params[i].seriesName}: ${params[i].data[1].toFixed(2)}`;
          }
          return result;
        },
      },
    } as EChartsOptionConfig;
  }
  getData() {
    const preview: any = [];
    const data = [];
    const date: Array<Date> = [];
    const value: Array<Array<{bin: string; value: number}>> = [];
    const raw: Array<{bin: string; values: Array<number>}> = [];

    if (this.sensorData[0]) {
      this.sensorData.forEach( s => {
        if (s === null) {
          data.push(null);
        } else {
          data.push(s.values);
          date.push(s.chunkDate);
          value.push(s.values);
        }
      });

      for (let i = 0; i < value[0].length; i++) {
        raw.push({ bin: value[0][i].bin, values: value.map((x) => x[i].value)});
      }

      const final = [];
      for (let i = 0; i < raw.length; i++) {
        const temp = [];
        for (let y = 0; y < date.length; y++) {
          temp.push([date[y], raw[i].values[y]]);
        }
        final.push({bin: raw[i].bin, data: temp});
      }

      final.forEach((element) => {
        preview.push({
          name: element.bin,
          data: element.data,
          showSymbol: false,
          type: "line",
          connectNulls: false
        });
      });
    }
    return preview;
  }
}
