







import { Component, Vue } from "vue-property-decorator"
import Dashboard from "@/modules/dashboard/Dashboard.vue"
import BottomNavBar from "@/modules/dashboard/DashboardBottomNavBar.vue"
import { Navigation } from "../modules/dashboard/dashboard.model";

@Component({
    components: {
        Dashboard,
        BottomNavBar
    }
})
export default class DashboardRouter extends Vue{
    observationType: Navigation = Navigation.Accrued;

    fullscreen: number | null = null;

    setObservation(type: Navigation){
        this.observationType = type;
    }

    changeFullscreenStatus(event: number | null) {
        this.fullscreen = event;
    }
}
