




































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  resetPassword,
  ResetPasswordCommand,
} from "@/commands/user/ResetPasswordCommand";
import { CommandResultStatus } from "../crs/crs";
import { dataSet } from "../dataSet";

@Component
export default class ResetPassword extends Vue {
  @Prop({required: true})
  hasInvalidToken: boolean | null;

  model = {
    email: "",
  };
  rules = {
    email: [
      { required: true, message: "E-mail requis" },
      { type: "email", message: "Format de l'e-mail invalide" },
    ],
  };
  loading = false;
  data = dataSet;

  submit(event?: Event) {
    this.loading = true;
    if (event) event.preventDefault();
    const form = this.$refs["form"] as Vue & {
      validate: (cb: (validate: boolean) => Promise<void>) => void;
    };
    form.validate(async (valid: boolean) => {
      if (valid) {
        const result = await resetPassword(new ResetPasswordCommand(this.model.email));
        if (result.status == CommandResultStatus.Ok) {
          this.$message({ type: 'success', message: 'Si cette adresse existe un email y a été envoyé', duration: 5000});
        } else {
          this.$message({ type: 'error', message: 'Erreur veuillez réessayer plus tard', duration: 5000});
        }
      }
      this.loading = false;
    });
  }
}
