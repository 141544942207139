

































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CurrentDashboardSelectedQuery } from "../../queries/dashboard/CurrentDashboardSelectedQuery";
import { CurrentUserDashboardsQuery } from "../../queries/dashboard/CurrentUserDashboardsQuery";
import DashboardForm from "@/modules/dashboard/DashboardForm.vue";
import {
  IDashboardForm,
  IDashboardConfig,
  IDashboardModel,
  IDashboardNB,
  ISensorsModel,
  IDashboardSensorConfig,
} from "./dashboard.model";
import { DashboardModel } from "./dashboardModel";
import {
  deleteDashboard,
  IDeleteDashboardCommand,
} from "../../commands/dashboard/DeleteDashboardCommand";
import { renameDashboard } from "../../commands/dashboard/RenameDashboardCommand";
import { setNoCurrentDashboard } from "../../backend/dashboard";
@Component({
  components: {
    DashboardForm,
  },
})
export default class DashboardList extends Vue {
  executor = CurrentUserDashboardsQuery.getExecutor(
    new CurrentUserDashboardsQuery()
  );
  edit = false;
  componentKey = 0;
  dashboardEdit = false;
  currentDashboardEdit: DashboardModel | null = null;
  nameEditingList: Array<any> = [];
  deletingDashboard: IDashboardNB = {
    dashboardId: 0,
    name: "",
    createDate: new Date(),
    nbSensor: 0,
  };
  deleteDialog = false;
  dashboardList: Array<IDashboardNB> = [];

  async mounted() {
    setNoCurrentDashboard();
    await this.getDashboard();
  }

  async getDashboard() {
    await this.executor.queryAll();
    this.executor.results$.value.forEach((element) => {
      const model = {
        id: element.dashboardId,
        set: false,
      };
      this.dashboardList.push({
        dashboardId: element.dashboardId,
        name: element.name,
        createDate: element.createDate,
        nbSensor: element.nbSensor,
      } as IDashboardNB);
      this.nameEditingList.push(model);
    });
  }

  toDashboard(id: number) {
    this.$router.replace("app/dashboard/" + id);
  }

  changeDashboardState() {
    this.dashboardEdit = false;
  }

  async openDashbordDeleteDialog(id: number) {
    this.deletingDashboard = this.dashboardList.filter(
      (element) => element.dashboardId == id
    )[0];
    this.deleteDialog = true;
  }

  addDashboard() {
    this.dashboardList = [];
    this.getDashboard();
  }

  add() {
    this.currentDashboardEdit = new DashboardModel();
    this.dashboardEdit = true;
  }

  renameThisDashboard(id: number, name: string) {
    const model = {
      dashboardId: id,
      name: name,
    };
    renameDashboard(model);
    this.nameEditingList.forEach((element) => {
      if (element.id == id) {
        element.set = false;
      }
    });
  }

  @Watch("nameEditingList", { deep: true })
  nameEdition(id: number) {
    return this.nameEditingList
      .filter((element) => element.id == id)
      .flatMap((element) => element.set)[0];
  }

  setEdition(id: number) {
    this.nameEditingList.forEach((element) => {
      if (element.id == id) {
        element.set = true;
      }
    });
  }

  getTime(date: Date) {
    return date.toLocaleDateString();
  }

  async removeDashboard(i: number) {
    this.dashboardList.splice(
      this.dashboardList.indexOf(this.deletingDashboard),
      1
    );
    const model = { dashboardId: i };
    await deleteDashboard(model);
    this.deleteDialog = false;
  }

  async openDashboardForm(id: number) {
    const model = {
      dashboardId: 0,
      name: "",
      config: [],
    } as IDashboardForm;
    const value = CurrentDashboardSelectedQuery.getExecutor(
      new CurrentDashboardSelectedQuery(id)
    );
    await value.queryAll();
    const raw = value.results$.value;
    const temp = JSON.parse(raw[0].sensors) as {
      ProductionSiteId: number;
      SensorId: number;
    }[];
    model.dashboardId = raw[0].dashboardId;
    model.name = raw[0].name;
    if (temp !== null) {
      temp.forEach((item) => {
        if (item.ProductionSiteId > 0 && item.SensorId > 0) {
          model.config.push({
            productionSiteId: item.ProductionSiteId,
            sensorId: item.SensorId,
          } as IDashboardSensorConfig);
        }
      });
    }
    this.currentDashboardEdit = new DashboardModel(model);
    this.dashboardEdit = true;
  }

  forceRerender() {
    this.componentKey += 1;
  }
}
