





















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AccruedChart from "../charts/AccruedChart.vue";
import HeaderResult from "./HeaderResult.vue";
import BottomBarResult from "./BottomBarResult.vue";
import { IDisplayConfig, ISensorsModel, ViewMode } from "../dashboard.model";
import {
  RealtimeHistData,
  registerHistRealtimeCallback,
  registerHistSlipperyCallback,
} from "../signalrService";
import {
  histCumul,
  HistCumulCommand,
} from "../../../commands/graph/HistCumulCommand";
import { convertTempDataToCSV, downloadCSV } from "@/backend/csvService";
import { FullTemporalPercCommand, fullTemporalPerc } from "@/commands/graph/FullTemporalPercCommand";
import moment from "moment";
@Component({
  components: {
    AccruedChart,
    HeaderResult,
    BottomBarResult,
  },
})
export default class HistSensor extends Vue {
  @Prop({ required: true })
  sensorModel!: ISensorsModel;

  @Prop({ required: true })
  currentMode: ViewMode;

  currentDisplayConfig: IDisplayConfig = this.sensorModel.displayConfig;

  @Prop({ required: true })
  fullscreen: boolean;

  isExpand = false;
  sensorData: RealtimeHistData[] = [];

  async downloadCSV() {
    // get temp data
    let start: Date;
    let end: Date;
    switch (this.currentMode) {
      case ViewMode.Slipery:
        end = new Date();
        // eslint-disable-next-line no-case-declarations
        const time = this.sensorModel.displayConfig.currentSliperyHour;
        start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          time.getHours(),
          time.getMinutes(),
          time.getSeconds()
        );
        break;
      case ViewMode.Fixed:
        start = this.currentDisplayConfig.currentFramedTime[0];
        end = this.currentDisplayConfig.currentFramedTime[1];
        break;
    
      default:
        end = new Date();
        // eslint-disable-next-line no-case-declarations
        const time2 = this.sensorModel.displayConfig.currentRealtimeDuration;
        start = new Date(
          end.getFullYear(),
          end.getMonth(),
          end.getDate(),
          time2.getHours(),
          time2.getMinutes(),
          time2.getSeconds()
        );
        break;
    }
    const res = await fullTemporalPerc(
      new FullTemporalPercCommand(
        start!,
        end!,
        this.sensorModel.displayConfig.currentBinId!,
        this.sensorModel.sensorId,
        this.currentDisplayConfig.minVolume,
        this.currentDisplayConfig.maxVolume,
        this.computeResolution(
          start!,
          end!
        )
      )
    );

    // const csv = convertHistDataToCSV(this.sensorData);
    const csv = convertTempDataToCSV(res.result.values);
    downloadCSV(csv, this.sensorModel);
  }

  tableData: { name: string; data: number[]; className: string }[] = [
    {
      name: "MAILLE",
      data: [],
      className: "maille-row",
    },
    {
      name: "MOYENNE XF",
      data: [],
      className: "moyennexf-row",
    },
    {
      name: "MAXIMUM",
      data: [],
      className: "maximum-row",
    },
    {
      name: "MINIMUM",
      data: [],
      className: "minimum-row",
    },
    {
      name: "ECART TYPEσ",
      data: [],
      className: "ecarttype-row",
    },
    {
      name: "XF + 1.25σ",
      data: [],
      className: "xfplus-row",
    },
    {
      name: "XF - 1.25σ",
      data: [],
      className: "xfminus-row",
    },
    {
      name: "VSS",
      data: [],
      className: "vss-row",
    },
    {
      name: "VSI",
      data: [],
      className: "vsi-row",
    },
  ];
  unregister: undefined | (() => void);

  async reloadLiveFeed() {
    this.callUnregister();
    if(this.sensorModel.displayConfig.currentBinId === undefined) return;
    if (this.currentMode == ViewMode.Slipery) {
      const today = new Date();
      const time = this.sensorModel.displayConfig.currentSliperyHour;
      const slipperyDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        time.getHours(),
        time.getSeconds(),
        time.getSeconds()
      );
      this.unregister = await registerHistSlipperyCallback(
        this.sensorModel.sensorId,
        this.sensorModel.displayConfig.currentBinId,
        slipperyDate,
        this.sensorModel.displayConfig.minVolume,
        this.sensorModel.displayConfig.maxVolume,
        this.onNewData
      );
    }
    if (this.currentMode == ViewMode.RealTime) {
      this.unregister = await registerHistRealtimeCallback(
        this.sensorModel.sensorId,
        this.sensorModel.displayConfig.currentBinId,
        this.sensorModel.displayConfig.currentRealtimeDuration,
        this.sensorModel.displayConfig.minVolume,
        this.sensorModel.displayConfig.maxVolume,
        this.onNewData
      );
    }
  }
  callUnregister() {
    if (this.unregister != undefined) this.unregister();
    this.unregister = undefined;
  }

  async graphModeChanged(mode: ViewMode) {
    const previousMode = this.currentMode;
    const loader = this.$loading({fullscreen: true, background: 'rgba(0, 0, 0, 0.7)'});
    this.currentDisplayConfig = this.sensorModel.displayConfig;
    this.callUnregister();
    this.$emit("changeGraphMode", mode);
    if (this.sensorModel.displayConfig.currentBinId === null) {
      alert("Ce tapis n'a pas de bin configuré.");
      return;
    }

    let attempt = 1;
    while(attempt <= 5) {
      try {
        switch (mode) {
          case ViewMode.Slipery:
            await this.reloadLiveFeed();
            break;
          case ViewMode.RealTime:
            await this.reloadLiveFeed();
            break;
          case ViewMode.Fixed:
            {
              if(this.sensorModel.displayConfig.currentBinId === undefined) return;
              const res = await histCumul(
                new HistCumulCommand(
                  this.sensorModel.displayConfig.currentFramedTime[0],
                  this.sensorModel.displayConfig.currentFramedTime[1],
                  this.sensorModel.displayConfig.currentBinId,
                  this.sensorModel.sensorId,
                  this.sensorModel.displayConfig.minVolume,
                  this.sensorModel.displayConfig.maxVolume
                )
              );
              this.onNewData(res.result.values);
            }
            break;
          default:
            break;
        }
        attempt = 6; // success: no need to attempt again
      } catch (e) {
        console.error(`Attempt ${attempt} failed`);
        console.error(e);
        attempt++;
        if (attempt > 5) {
          this.$message({ type: 'error', message: 'Erreur, veuillez réessayer'});
          this.$emit("changeGraphMode", previousMode);
        }
      }
    }
    loader.close();
  }

  mounted() {
    this.refreshOnConfigChange();
  }

  @Watch("sensorModel.displayConfig", { deep: true })
  async refreshOnConfigChange() {
    this.graphModeChanged(this.currentMode);
  }

  onNewData(args: RealtimeHistData[]): void {
    const sData = args
      .sort((a, b) => a.valueIndex - b.valueIndex)
      .filter(
        (s) => s.average >= this.sensorModel.displayConfig.thresholdHistCumul
      );
    this.sensorData = sData;
    for (const item of this.tableData) {
      switch (item.name) {
        case "MAILLE":
          item.data = sData.map((s) => s.bin);
          break;
        case "MOYENNE XF":
          item.data = sData.map((s) => s.average);
          break;
        case "MAXIMUM":
          item.data = sData.map((s) => s.max);
          break;
        case "MINIMUM":
          item.data = sData.map((s) => s.min);
          break;
        case "ECART TYPEσ":
          item.data = sData.map((s) => s.stdev);
          break;
        case "XF + 1.25σ":
          item.data = sData.map((s) => s.average + 1.25 * s.stdev);
          break;
        case "XF - 1.25σ":
          item.data = sData.map((s) => s.average - 1.25 * s.stdev);
          break;
        case "VSS":
          item.data = sData.map((s) => s.vss);
          break;
        case "VSI":
          item.data = sData.map((s) => s.vsi);
          break;
        default:
          break;
      }
    }
  }

  changeFullscreenStatus(event: number | null) {
    this.$emit("to-fullscreen", event);
  }

  switchExpand() {
    this.isExpand = !this.isExpand;
  }
  classType(rowId: number, columnId: number, columnLength: number) {
    if (rowId < 2) {
      if (columnId < columnLength - 1) {
        return "data tableHeader";
      }
      return "data tableHeader end";
    }
    if (columnId < columnLength - 1) {
      return "data";
    }
    return "data end";
  }
  classRow(rowName: string) {
    if (this.isExpand) {
      return rowName + " isExpand";
    }
    return rowName;
  }

  computeResolution(start: Date, end: Date): 0 | 1 | 2 {
    const days = moment(end).diff(start, "days");
    if (days < 5) {
      //configurable ?
      return 0;
    }
    if (days < 60) {
      return 1;
    }
    return 2;
  }
}
