import { IAuthServiceConfiguration } from '@signature/webfrontauth';
import http from "axios";

export let appsettings: Config;

export async function loadConfig() {
  if(appsettings !== undefined) return;
  try {
    const response = await http.get(`/appSettings.json`);
    appsettings = response.data as Config;
  } catch( e ) {
    const isHttps = window.location.protocol.toLowerCase() === 'https:';
    const baseUrl = window.location.host;
  appsettings = {
      AuthService : {
        identityEndPoint: {
          hostname: window.location.hostname,
          port: window.location.port
            ? Number(window.location.port)
            : undefined,
          disableSsl: !isHttps
        }
      },
      CrsEndpoint : `/crs-dispatcher`,
      QrsEndpoint: `/qrs`,
      QrsAdminEndpoint: `/admin`,
      SignalRServer: ``
    }
  }
}

interface Config {
  AuthService: IAuthServiceConfiguration;
  CrsEndpoint: string;
  QrsEndpoint: string;
  QrsAdminEndpoint: string;
  SignalRServer: string;
}
