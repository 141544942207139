
import { AdminBinIdQuery } from '@/queries/dashboard/AdminBinIdQuery';
import { AdminBinIdValuesQuery } from '@/queries/dashboard/AdminBinIdValuesQuery';
import { BinIdQuery } from "@/queries/dashboard/BinIdQuery";
import { BinIdValuesQuery } from '@/queries/dashboard/BinIdValuesQuery';

export async function getSensorBins(sensorId: number) {
    if (sensorId === undefined) throw new Error();
    const a = BinIdQuery.getExecutor(new BinIdQuery(sensorId));
    return await a.queryAll();
}

export async function getBinValues(binId: number) {
    const a = BinIdValuesQuery.getExecutor(new BinIdValuesQuery(binId));
    return await a.queryAll();
}


export async function getAdminSensorBins(sensorId: number) {
    if (sensorId === undefined) throw new Error();
    const a = AdminBinIdQuery.getExecutor(new AdminBinIdQuery(sensorId));
    return await a.queryAll();
}

export async function getAdminBinValues(binId: number) {
    const a = AdminBinIdValuesQuery.getExecutor(new AdminBinIdValuesQuery(binId));
    return await a.queryAll();
}
