






import { Component, Vue } from "vue-property-decorator"
import DashboardList from '@/modules/dashboard/DashboardList.vue';

@Component({
    components: {
        DashboardList
    }
})
export default class extends Vue {

}
