import { BehaviorSubject } from "rxjs";
import { ICurrentDashboard, IDashboardForm } from '../modules/dashboard/dashboard.model';
import { CurrentUserDashboardFeatureGrantLevelQuery } from '@/queries/dashboard/CurrentUserDashboardFeatureGrantLevelQuery';
import { CurrentUserDashboardsQuery } from '@/queries/dashboard/CurrentUserDashboardsQuery';
import { updateDashboard, IUpdateDashboardCommand } from '@/commands/dashboard/UpdateDashboardCommand'
import { getAuthenticatedUser } from '@/modules/authentication/index'
import { DashboardGrantLevelQuery, DashboardGrantLevelQueryResult } from "@/queries/dashboard/DashboardGrantLevelQuery";
export const currentDashboard$ = new BehaviorSubject<ICurrentDashboard | null>(null);
export function setNoCurrentDashboard() {
  currentDashboard$.next(null);
}
export async function setCurrentDashboardFromId(dashboardId: number | null) {
  const q = new CurrentUserDashboardsQuery();
  q.filterById = dashboardId === null ? undefined : dashboardId;

  const r = await CurrentUserDashboardsQuery.getExecutor(q).queryFirst();

  if (!r) {
    currentDashboard$.next(null);
    return false;
  } else {
    await setCurrentDashboard(r.dashboardId, r.name, r.dashboardGrantLevel);
    return true;
  }
}

export async function setCurrentDashboard(dashboardId: number, name: string, grantLevel: number) {
  const exe = CurrentUserDashboardFeatureGrantLevelQuery.getExecutor(
    new CurrentUserDashboardFeatureGrantLevelQuery(dashboardId)
  );
  const levels = await exe.queryAll();

  if (levels && levels.length) {

    const model: ICurrentDashboard = {
      dashboardId,
      name,
      grantLevel
    };
    currentDashboard$.next(model);
  } else {
    currentDashboard$.next(null);
  }
}

export function saveDashboard(dashboardId: number, dashboard: IDashboardForm) {
  const command: IUpdateDashboardCommand = {
    dashboardId: dashboard.dashboardId,
    name: dashboard.name,
    config: dashboard.config.map(s => {
      return {
        productionSiteId: s.productionSiteId == '' ? 0 : s.productionSiteId,
        sensorId: s.sensorId == '' ? 0 : s.sensorId
      }
    })
  }
  return updateDashboard(command);
}

export async function getDashboardGrantLevel(userId: number) {
  const query = DashboardGrantLevelQuery.getExecutor(new DashboardGrantLevelQuery(userId));
  return await query.queryAll(true);
}
