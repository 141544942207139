









import { Component, Vue } from "vue-property-decorator";
import ChangePassword from '@/modules/authentication/ChangePassword.vue';
import ResetPassword from '@/modules/authentication/ResetPassword.vue';
import { validateLostPasswordToken, ValidateLostPasswordTokenCommand } from '@/commands/user/ValidateLostPasswordTokenCommand';
import { CommandResultStatus } from "@/modules/crs/crs";

@Component({
    components: {
      ChangePassword,
      ResetPassword
    }
})
export default class ResetPasswordPage extends Vue {
  token: string | null = null;
  hasValidToken = false;
  hasInvalidToken = false;

  async mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token as string;
      const result = await validateLostPasswordToken(new ValidateLostPasswordTokenCommand(this.token));

      if (result.status === CommandResultStatus.Ok) {
        this.hasValidToken = true;
      } else {
        this.hasInvalidToken = true;
      }
    } else {
      this.token = null
    }
  }
}
