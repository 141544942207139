



































































































































import { Component, Vue } from "vue-property-decorator";
import { getAllProductionSite } from "@/backend/productionSite";
import { IProductionSiteModel } from "../dashboard/dashboard.model";
import {
  createProductionSite,
  CreateProductionSiteCommand,
} from "../../commands/productionSite/CreateProductionSiteCommand";
import BackofficeProductionSite from "./BackofficeProductionSite.vue";
import { getAllUsers } from "../../backend/user";
import { MultiUserResult } from "@/queries/user/AllUserQuery";
import BackofficeUser from "./BackofficeUser.vue";
import {
  createUser,
  CreateUserCommand,
} from "@/commands/user/CreateUserCommand";
@Component({
  components: {
    BackofficeProductionSite,
    BackofficeUser,
  },
})
export default class Backoffice extends Vue {
  createProductionName = "";
  newProductionSite = "";
  productionSiteArray: IProductionSiteModel[] = [];
  selectedSiteId: number | "" = "";
  newUserEmail = "";
  newUserPassword = "";
  newUserFirstName = "";
  newUserLastName = "";
  selectedUserId: number | "" = "";
  userArray: MultiUserResult[] = [];

  backofficeContext = "createSite";

  get selectedProductionSite(): IProductionSiteModel | undefined {
    return this.productionSiteArray.find(
      (s) => s.productionSiteId == this.selectedSiteId
    );
  }
  async refreshData() {
    this.productionSiteArray = await getAllProductionSite();
    this.userArray = await getAllUsers();
  }
  async mounted() {
    await this.refreshData();
  }

  async onCreateProduction() {
    const name = this.createProductionName;
    this.createProductionName = "";
    const res = await createProductionSite(
      new CreateProductionSiteCommand(name)
    );
    await this.refreshData();
  }

  async createUser() {
    await createUser(
      new CreateUserCommand(
        this.newUserFirstName,
        this.newUserLastName,
        this.newUserEmail,
        this.newUserPassword
      )
    );
    await this.refreshData();
  }

  changeBackofficeContext(context: string) {
    this.backofficeContext = context;
  }
}
