import { AxiosRequestConfig, AxiosResponse } from 'axios'
import * as URI from 'urijs'
import { readQueryName } from './query'
import { default as diff } from 'deep-diff';
import { plainToClass } from "class-transformer";
import { BehaviorSubject } from 'rxjs'
import { IReadOnlyBehaviorSubject } from '../../modules/utils/rx'
import backendAxios from "@/modules/axios/axios";
import {
    IQueryResult,
    IPaginatedQuery, IPaginatedQueryResult, ProtectedMemory, SearchDirection
} from './model'
import { appsettings } from '../config/appSettings'

export interface IConstructor<T> {
    new(...args: any[]): T;
}

export class QueryExecutor<TQuery, TResult> {
    protected _resultType: IConstructor<TResult>;
    protected _endpoint = 'query';

    protected _results$: BehaviorSubject<TResult[]>;
    protected _firstResult$: BehaviorSubject<TResult | undefined>;
    protected _isLoading$: BehaviorSubject<boolean>;

    constructor(resultType: IConstructor<TResult>, query?: TQuery) {
        this.query = query;
        this._resultType = resultType;

        this._isLoading$ = new BehaviorSubject<boolean>(false);
        this._results$ = new BehaviorSubject<TResult[]>([]);
        this._firstResult$ = new BehaviorSubject<TResult | undefined>(undefined);

        this._results$.subscribe(r => {
            if (!r || !r.length) this._firstResult$.next(undefined);
            else this._firstResult$.next(r[0]);
        });
    }

    /**
     * The current query object.
     */
    public query?: TQuery;

    public setQuery(query: TQuery) {
        this.query = query;
        return this;
    }

    get isLoading$(): IReadOnlyBehaviorSubject<boolean> {
        return this._isLoading$;
    }

    /**
     * Observable results of the last fetch.
     */
    get results$(): IReadOnlyBehaviorSubject<TResult[]> {
        return this._results$;
    }

    /**
     * Observable first result of the last fetch.
     */
    get firstResult$(): IReadOnlyBehaviorSubject<TResult | undefined> {
        return this._firstResult$;
    }

    /**
     * This method is called at the configuration of the request. You can alter the request's configuration here. Don't forget to call super().
     * @param config
     */
    protected onRequestConfig(config: AxiosRequestConfig) { }

    /**
     * This method is called after the request and before the result is returned. You can read or alter the result here. Don't forget to call super().
     * @param response
     */
    protected onRequestResponse(response: AxiosResponse<IQueryResult<TResult>>) {
        if (response.data == null) throw new Error("QRS: query response is empty.");
        if (response.data.items == null) throw new Error("QRS: query response does not contain an 'items' property.");

        response.data.items = response.data.items.map(x => plainToClass(this._resultType, x));

        this._results$.next(response.data.items);
    }

    /**
     * This method is called just before the request is sent. If it result is true, the request is sent. Otherwise, the current result is returned.
     * Don't forget to handle the result of super() if you inherit from this class.
     */
    protected validateBeforeQuery(): boolean {
        return true;
    }

    protected async executeQuery<TResultWrapper extends IQueryResult<TResult>>(admin: boolean): Promise<TResult[]> {
        this._isLoading$.next(true);

        try {
            if (this.query == null) throw new Error("Executor's query must be set before executing any query.");

            const queryName = readQueryName(this.query);

            const uri = URI.default(admin ? appsettings.QrsAdminEndpoint : appsettings.QrsEndpoint)
                .segment(this._endpoint)
                .segment(queryName).toString();

            this.onRequestConfig({});

            const canSend = this.validateBeforeQuery();

            if (canSend) {
                const result = await backendAxios.post<TResultWrapper>(uri, this.query);
                this.onRequestResponse(result);
                return result.data.items;
            }
            else {
                return this.results$.value || [];
            }
        }
        finally {
            this._isLoading$.next(false);
        }
    }

    async queryAll(admin?: boolean): Promise<TResult[]> {
        if (admin === undefined) admin = false;
        return await this.executeQuery<IQueryResult<TResult>>(admin);
    }

    async queryFirst(admin?: boolean): Promise<TResult | undefined> {
        if (admin === undefined) admin = false;
        const results = await this.queryAll(admin);
        if (!results || !results.length) return undefined;
        return results[0];
    }
}

export class PaginatedQueryExecutor<TQuery extends IPaginatedQuery, TResult> extends QueryExecutor<TQuery, TResult> {
    private _lastMemory: ProtectedMemory | null;
    private _lastQuery: object;

    private _canGoBack$: BehaviorSubject<boolean>;
    private _canGoNext$: BehaviorSubject<boolean>;
    private _resultsCount$: BehaviorSubject<number>;

    constructor(resultType: IConstructor<TResult>, query?: TQuery) {
        super(resultType, query);
        this._endpoint = 'search';

        this._canGoBack$ = new BehaviorSubject<boolean>(false);
        this._canGoNext$ = new BehaviorSubject<boolean>(false);
        this._resultsCount$ = new BehaviorSubject<number>(0);
    }

    get canGoBack$(): IReadOnlyBehaviorSubject<boolean> {
        return this._canGoBack$;
    }

    get canGoNext$(): IReadOnlyBehaviorSubject<boolean> {
        return this._canGoNext$;
    }

    get resultsCount$(): IReadOnlyBehaviorSubject<number> {
        return this._resultsCount$;
    }

    protected onRequestConfig(config: AxiosRequestConfig) {
        super.onRequestConfig(config);
        if (this.query?.direction == SearchDirection.Reset && this._lastMemory) {
            this._lastMemory = null;
        }
        else {
            this.processQueryChanges();
        }

        config.headers = config.headers || {};
        config.headers['X-Qrs-Search-Memory'] = this._lastMemory || '';
    }

    private processQueryChanges() {
        const currentQuery = JSON.parse(JSON.stringify(this.query));

        if (this._lastQuery) {
            const changes = diff.diff(this._lastQuery, currentQuery);
            let needsReset = false;

            if (changes && changes.length > 0) {
                for (const c of changes) {
                    if (c.path.join('/') == 'direction') continue;
                    else {
                        needsReset = true;
                        break;
                    }
                }

                if (needsReset) {
                    this._lastMemory = null;
                }
            }
        }

        this._lastQuery = currentQuery;
    }

    protected validateBeforeQuery(): boolean {
        if (this._lastMemory) {
            if (!this._canGoBack$.value && this.query?.direction == SearchDirection.Prev) return false;
            else if (!this._canGoNext$.value && this.query?.direction == SearchDirection.Next) return false;
        }

        return true;
    }

    protected onRequestResponse(response: AxiosResponse<IPaginatedQueryResult<TResult>>) {
        super.onRequestResponse(response);

        this._lastMemory = response.headers['x-qrs-search-memory'];

        this._canGoNext$.next(response.data.canGoNext);
        this._canGoBack$.next(response.data.canGoPrev);
        this._resultsCount$.next(response.data.count);
    }

    perPage(n: number) {
        if (this.query == null) throw new Error("Executor's query must be set before executing any query.");

        this.query.perPage = n;
        return this;
    }

    nextPage() {
        if (this.query == null) throw new Error("Executor's query must be set before executing any query.");

        this.query.direction = SearchDirection.Next;
        return this;
    }

    currentPage() {
        if (this.query == null) throw new Error("Executor's query must be set before executing any query.");

        this.query.direction = SearchDirection.Current;
        return this;
    }

    prevPage() {
        if (this.query == null) throw new Error("Executor's query must be set before executing any query.");

        this.query.direction = SearchDirection.Prev;
        return this;
    }

    firstPage() {
        if (this.query == null) throw new Error("Executor's query must be set before executing any query.");

        this.query.direction = SearchDirection.Reset;
        return this;
    }

    resetPage() {
        return this.firstPage();
    }
}
